export const columnOrderForAptNoInfo = [
    'complexName',
    'aptAddress',    
    'aptNo',   
]

export const columnOrderForAptInfo = [
    'apartment_name',
    'apartment_number',
    'total_households',
    'total_parking_spaces',
    'total_building_count',
    'move_in_date',
    'floor_area_ratio',
    'building_coverage_ratio',
    'address',
    'size_type',
    'supply_area',
    'exclusive_area',
    'exclusive_ratio',
    'type',
    'household_count',
    'rooms_bathrooms',
    'sale_price',
    'jeonse_price',
    'trading_gap',
    'jeonse_ratio',
    'recent_actual_transaction_floor',
    'recent_actual_transaction_formattedtradeyearmonth',
    'recent_actual_transaction_formattedprice',
    'recent_actual_transaction_representativearea',
    'recent_actual_transaction_exclusivearea',
    "actual_sale_transaction_price",
    "actual_sale_transaction_date",
    "actual_jeonse_transaction_price",
    "actual_jeonse_transaction_date",
    "previous_peak_price_price",
    "previous_peak_price_date",
    "previous_lowest_price_price",
    "previous_lowest_price_date",
    'current_vs_peak_price',
    'peak_comparison',
    '매물_리스트_확인',
    'sale_count',
    'jeonse_count',
    'schoolname',
    'studentcountperclassroom',
    'walktime',
    'organizationtype',
];

export const columnOrderForAptA1B1Info = [
    "complexName",
    "공급면적",
    "전용면적",
    "거래방식",
    "확인매물",
    "동",
    "direction",
    "가격",
    "층",
    "동일매물",
    "상세내용",
    "Tag",
    "atclNo"
]

export const columnOrderForAptMetrix = [
    'subdistrict_name',
    'apartment_name',
    'apartment_number',
    'total_households',
    'total_parking_spaces',
    'total_building_count',
    'move_in_date',
    'floor_area_ratio',
    'building_coverage_ratio',
    'address',
    'size_type',
    'supply_area',
    'exclusive_area',
    'exclusive_ratio',
    'type',
    'household_count',
    'rooms_bathrooms',
    'sale_price',
    'jeonse_price',
    'trading_gap',
    'jeonse_ratio',
    'recent_actual_transaction_floor',
    'recent_actual_transaction_formattedtradeyearmonth',
    'recent_actual_transaction_formattedprice',
    'recent_actual_transaction_representativearea',
    'recent_actual_transaction_exclusivearea',
    "actual_sale_transaction_price",
    "actual_sale_transaction_date",
    "actual_jeonse_transaction_price",
    "actual_jeonse_transaction_date",
    "previous_peak_price_price",
    "previous_peak_price_date",
    "previous_lowest_price_price",
    "previous_lowest_price_date",
    'current_vs_peak_price',
    'peak_comparison',
    '매물_리스트_확인',
    'sale_count',
    'jeonse_count',
    'schoolname',
    'studentcountperclassroom',
    'walktime',
    'organizationtype',
];
