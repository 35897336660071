import React, { useState, useEffect } from 'react';
import SignInUp from './SignInUp';
import { supabase } from '../util/supabaseClient';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ element, session }) => {
  const navigate = useNavigate();
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!session) {
      // Show login message and redirect after a delay
      setShowLoginMessage(true);
      
      // Clear the message after navigation
      return () => setShowLoginMessage(false);
    }
  }, [session]);

  const handleLoginClick = (e) => {
    e.preventDefault();
    setShowModal(true);
    setShowLoginMessage(false);
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    setShowModal(false);
    setShowLoginMessage(true);
    navigate('/');
  };

  // If user is authenticated, render the protected component
  if (session) {
    return element;
  }
  
  // If not authenticated, redirect to home page
  return (
    <>
      {showLoginMessage && (
        <div className="fixed top-0 left-0 w-full bg-red-500 text-white p-8 text-center z-50">
          로그인이 필요한 서비스입니다. <span onClick={handleLoginClick} className="underline font-bold hover:cursor-pointer">로그인</span> 후 이용해주세요.
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 bg-[#FFFEF6]  bg-opacity-100 flex items-center justify-center z-50">
          <div className="bg-[#FFFEF6] p-10 rounded-lg relative border shadow-xl ">
            <button
              onClick={handleCloseClick}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <SignInUp supabase={supabase} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProtectedRoute; 