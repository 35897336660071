import React, { useState, useEffect, useRef } from 'react';
import cityCodes from '../constant/city_codes.json';

const QueryInputComponent = ({ onQuerySubmit }) => {
  const [locationText, setLocationText] = useState('');
  const [filterText, setFilterText] = useState('');
  const [isLocationFocused, setIsLocationFocused] = useState(false);
  const [isFilterFocused, setIsFilterFocused] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);
  
  // Process city codes data for faster lookup
  const locationData = useRef({
    regions: [],
    districts: {},
    neighborhoods: {}
  });
  
  useEffect(() => {
    // Process the city codes data once on component mount
    const regions = new Set();
    const districts = {};
    const neighborhoods = {};
    
    cityCodes.forEach(item => {
      if (item.지역명) {
        regions.add(item.지역명);
        
        // Group districts by region
        if (!districts[item.지역명]) {
          districts[item.지역명] = new Set();
        }
        if (item.시군구명) {
          districts[item.지역명].add(item.시군구명);
        }
        
        // Group neighborhoods by district
        const districtKey = `${item.지역명}-${item.시군구명}`;
        if (!neighborhoods[districtKey]) {
          neighborhoods[districtKey] = new Set();
        }
        if (item.읍면동명) {
          neighborhoods[districtKey].add(item.읍면동명);
        }
      }
    });
    
    // Convert Sets to arrays
    locationData.current = {
      regions: Array.from(regions),
      districts: Object.fromEntries(
        Object.entries(districts).map(([key, value]) => [key, Array.from(value)])
      ),
      neighborhoods: Object.fromEntries(
        Object.entries(neighborhoods).map(([key, value]) => [key, Array.from(value)])
      )
    };
  }, []);

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setLocationText(value);
    
    if (value.trim().length > 0) {
      // Generate suggestions based on input
      const inputWords = value.trim().split(/\s+/);
      const lastWord = inputWords[inputWords.length - 1].toLowerCase();
      
      if (lastWord.length >= 1) {
        // Search for matching regions, districts, and neighborhoods
        const matchingRegions = locationData.current.regions
          .filter(region => region.toLowerCase().includes(lastWord))
          .slice(0, 5);
          
        const matchingDistricts = [];
        Object.entries(locationData.current.districts).forEach(([region, districtList]) => {
          districtList.forEach(district => {
            if (district.toLowerCase().includes(lastWord)) {
              matchingDistricts.push(`${district}`);
            }
          });
        });
        
        const matchingNeighborhoods = [];
        Object.entries(locationData.current.neighborhoods).forEach(([districtKey, neighborhoodList]) => {
          const [region, district] = districtKey.split('-');
          neighborhoodList.forEach(neighborhood => {
            if (neighborhood.toLowerCase().includes(lastWord)) {
              matchingNeighborhoods.push(`${neighborhood}`);
            }
          });
        });
        
        // Combine and limit suggestions
        const allSuggestions = [
          ...matchingRegions,
          ...matchingDistricts.slice(0, 5),
          ...matchingNeighborhoods.slice(0, 5)
        ].slice(0, 10);
        
        setSuggestions(allSuggestions);
        setShowSuggestions(allSuggestions.length > 0);
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    // Get the current location text and split it into words
    const words = locationText.trim().split(/\s+/);
    
    // Remove the last word (the one being typed)
    if (words.length > 0) {
      words.pop();
    }
    
    // Create the new query by joining the remaining words and adding the selected suggestion
    const newLocation = words.length > 0 
      ? `${words.join(' ')} ${suggestion}`
      : suggestion;
    
    // Update the state with the new location
    setLocationText(newLocation);
    
    // Hide suggestions
    setShowSuggestions(false);
    
    // Focus back on the input field
    setTimeout(() => {
      const inputField = document.querySelector('input[name="location"]');
      if (inputField) {
        inputField.focus();
      }
    }, 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();    
    // Create query parts
    const locationPart = locationText.trim();
    const filterPart = filterText.trim();
    
    // If both fields are empty or contain only whitespace, show all results
    const combinedQuery = locationPart || filterPart
      ? `${locationPart} ${filterPart}`.trim()
      : `all`;

    onQuerySubmit(combinedQuery);
    setShowSuggestions(false);
  };

  const handleLocationFocus = () => {
    setIsLocationFocused(true);
  };

  const handleLocationBlur = () => {
    // Delay hiding suggestions to allow clicking on them
    setTimeout(() => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(document.activeElement)) {
        setShowSuggestions(false);
        setIsLocationFocused(false);
      }
    }, 200);
  };

  const handleFilterFocus = () => {
    setIsFilterFocused(true);
  };

  const handleFilterBlur = () => {
    setIsFilterFocused(false);
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mb-2">
      <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-3">
        <div className={`relative border-2 rounded-lg transition-all duration-200 md:w-1/5 ${
          isLocationFocused ? 'border-blue-500 shadow-md' : 'border-gray-300'
        }`}>
          <input
            type="text"
            name="location"
            value={locationText}
            onChange={handleLocationChange}
            onFocus={handleLocationFocus}
            onBlur={handleLocationBlur}
            placeholder="지역을 입력하세요"
            className="w-full p-2 rounded-lg focus:outline-none"
          />
          
          {showSuggestions && suggestions.length > 0 && (
            <div 
              ref={suggestionsRef}
              className="absolute z-50 w-full bg-white mt-1 border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto"
            >
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className={`flex-grow relative border-2 rounded-lg transition-all duration-200 md:w-1/4 ${
          isFilterFocused ? 'border-blue-500 shadow-md' : 'border-gray-300'
        }`}>
          <input
            type="text"
            name="filter"
            value={filterText}
            onChange={handleFilterChange}
            onFocus={handleFilterFocus}
            onBlur={handleFilterBlur}
            placeholder="아래 항목들을 활용해 아파트를 검색하세요"
            className="w-full p-2 rounded-lg focus:outline-none"
          />
        </div>
        
        <button
          type="submit"
          className="bg-green-600 hover:bg-green-700 text-white text-sm font-bold p-2 rounded-lg transition-colors duration-200 hover:cursor-pointer md:w-1/6"
        >
          AI 검색
        </button>
      </form>
      
      <div className="mt-3">
        <div className="mt-2 flex flex-wrap gap-1">
          {[
            "광진구, 전고점 가장 높은, 전용면적 84, 탑15",
            "사당동, 평당 가격이 높은 아파트, 탑30",
            "경기도, 전고점 대비 현재 매매가가 가장 낮은 아파트 10위",
            "남양주시, 1000세대 이상 7억이상 8억이하 아파트",
            "강남구, 신축아파트 면적85이하, 2호선 역세권, 전매갭 20억 이하",
            "동작구, 전고점대비 현재 매매가 낮은 아파트, 지하철 도보시간 10분이내",
          ].map((example, index) => (
            <span
              key={index}
              className="inline-block bg-gray-200 rounded-lg px-2 py-1 text-xs text-gray-700 transition-colors duration-200"
            >
              {example}
            </span>
          ))}
        </div>
        
        <div className="mt-4">
          <p className="text-sm text-gray-500 mb-2">검색에 활용 가능한 항목들:</p>
          <div className="flex flex-wrap gap-2">
            {/* 아파트 기본 정보 - 연한 파란색 */}
            <span className="px-2 py-1 bg-blue-100 rounded-md text-xs">아파트 이름</span>
            <span className="px-2 py-1 bg-blue-100 rounded-md text-xs">주소</span>
            <span className="px-2 py-1 bg-blue-100 rounded-md text-xs">지역명</span>
            <span className="px-2 py-1 bg-blue-100 rounded-md text-xs">시/군/구명</span>
            <span className="px-2 py-1 bg-blue-100 rounded-md text-xs">읍/면/동명</span>
            
            {/* 아파트 구조 및 규모 - 연한 초록색 */}
            <span className="px-2 py-1 bg-green-100 rounded-md text-xs">총 세대수</span>
            <span className="px-2 py-1 bg-green-100 rounded-md text-xs">최고 층수</span>
            <span className="px-2 py-1 bg-green-100 rounded-md text-xs">최저 층수</span>
            <span className="px-2 py-1 bg-green-100 rounded-md text-xs">총 주차 공간</span>
            <span className="px-2 py-1 bg-green-100 rounded-md text-xs">입주일</span>
            
            {/* 면적 정보 - 연한 노란색 */}
            <span className="px-2 py-1 bg-yellow-100 rounded-md text-xs">공급면적</span>
            <span className="px-2 py-1 bg-yellow-100 rounded-md text-xs">전용면적</span>
            <span className="px-2 py-1 bg-yellow-100 rounded-md text-xs">전용률</span>
            <span className="px-2 py-1 bg-yellow-100 rounded-md text-xs">세대수</span>
            <span className="px-2 py-1 bg-yellow-100 rounded-md text-xs">방/욕실 수</span>
            
            {/* 가격 정보 - 연한 빨간색 */}
            <span className="px-2 py-1 bg-red-100 rounded-md text-xs">매매가</span>
            <span className="px-2 py-1 bg-red-100 rounded-md text-xs">전세가</span>
            <span className="px-2 py-1 bg-red-100 rounded-md text-xs">전매갭</span>
            <span className="px-2 py-1 bg-red-100 rounded-md text-xs">전세가율</span>
            
            {/* 가격 변동 정보 - 연한 보라색 */}
            <span className="px-2 py-1 bg-purple-100 rounded-md text-xs">이전 최고가(전고점 매매)</span>
            <span className="px-2 py-1 bg-purple-100 rounded-md text-xs">이전 최고가(전고점 매매)거래일</span>
            <span className="px-2 py-1 bg-purple-100 rounded-md text-xs">현재가 대비 최고가(전고점 대비 현재가)</span>
            
            {/* 실거래 정보 - 연한 주황색 */}
            <span className="px-2 py-1 bg-orange-100 rounded-md text-xs">실제 매매 거래가(실거래 매매가)</span>
            <span className="px-2 py-1 bg-orange-100 rounded-md text-xs">실제 매매 거래일</span>
            <span className="px-2 py-1 bg-orange-100 rounded-md text-xs">실제 전세 거래가(실거래 전세가)</span>
            <span className="px-2 py-1 bg-orange-100 rounded-md text-xs">실제 전세 거래일</span>
            
            {/* 거래 통계 - 연한 회색 */}
            <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">매매 건수</span>
            <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">전세 건수</span>
            
            {/* 주변 시설 정보 - 연한 청록색 */}
            <span className="px-2 py-1 bg-teal-100 rounded-md text-xs">학교 이름</span>
            <span className="px-2 py-1 bg-teal-100 rounded-md text-xs">교실당 학생 수</span>
            <span className="px-2 py-1 bg-teal-100 rounded-md text-xs">지하철역 이름</span>
            <span className="px-2 py-1 bg-teal-100 rounded-md text-xs">지하철 노선명</span>
            <span className="px-2 py-1 bg-teal-100 rounded-md text-xs">지하철 도보시간</span>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryInputComponent; 