import React from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';

export default function SignInUp({ supabase }) {
    return (
        <div id='auth'>
            {/* <div>
                <a 
                    className="download-button" 
                    href="https://play.google.com/store/apps/details?id=com.jinhom6225.real_time_apt_list"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img 
                    src="./appIcon.png" 
                    alt="앱 아이콘" 
                    className="app-icon" 
                    />
                    <span>실시간 아파트 다운받기</span>
                </a>
            </div> */}
            <div className="auth-container">
                <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    providers={['google']}
                    view="sign_in"
                    onlyThirdPartyProviders={true}
                    options={{
                        redirectTo: window.location.origin // 로그인 성공시 돌아올 URL
                    }}
                />
            </div>
        </div>
    );
} 