import React from 'react';
import { useNavigate } from 'react-router-dom';

function Cancel() {
  const navigate = useNavigate();

  return (
    <div className="max-w-4xl mx-auto py-12 px-4">
      <div className="bg-white shadow-lg rounded-lg p-8 text-center">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">결제가 취소되었습니다</h2>
        <p className="text-gray-600 mb-8">
          결제 과정이 취소되었습니다. 언제든지 다시 시도하실 수 있습니다.
        </p>
        <div className="flex justify-center space-x-4">
          <button 
            onClick={() => navigate('/price')}
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
          >
            구독 페이지로 돌아가기
          </button>
          <button 
            onClick={() => navigate('/')}
            className="bg-gray-200 text-gray-800 px-6 py-2 rounded-md hover:bg-gray-300"
          >
            홈으로 이동
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cancel;