import React from 'react';

const CustomSelect = ({ value, onChange, options, placeholder = "", className = "" }) => (
    <select 
        className={`px-2 py-1 rounded-full text-green-600 bg-white focus:outline-none cursor-pointer text-sm w-full sm:w-auto ${className}`}
        value={value}
        onChange={onChange}
    >
        {placeholder && <option value="">{placeholder}</option>}
        {options?.map((option) => (
            <option 
                key={option.value || option.cortarNo || String(Math.random())} 
                value={option.value || option.cortarNo}
            >
                {option.label || option.cortarName}
            </option>
        ))}
    </select>
);

export default CustomSelect; 