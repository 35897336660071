import React from 'react';
import { Link } from 'react-router-dom';

const ComplexCard = ({ complex, setFormData, getAptInfo }) => {
    return (
        <div className="border rounded-lg p-3 shadow-md bg-yellow-50 w-68 flex flex-col justify-between">
            <div>
                <p><strong>아파트_No:</strong> {complex.complexNo}</p>
                <p><strong>사용승인일:</strong> {complex.useApproveYmd}</p>
                <p><strong>총세대:</strong> {complex.totalHouseholdCount}</p>
                <p><strong>총동수:</strong> {complex.totalBuildingCount}</p>
                <p><strong>매매:</strong> {complex.dealCount} | <strong>전세:</strong> {complex.leaseCount}</p>
            </div>
            {/* <button 
                className="mt-4 w-full bg-blue-400 text-white p-1 rounded"
                onClick={() => {
                    getAptInfo(complex.complexNo)
                }}
                >
                {complex.complexName}
            </button> */}
            <Link to={`/apartment/${complex.complexNo}`} className="mt-4 w-full border border-gray-200 bg-orange-100 hover:bg-orange-200 text-black rounded underline p-1 inline-block text-center">
                {complex.complexName}
            </Link>
        </div>
    );
};

export default ComplexCard;
