import React, { useState, useEffect } from "react";
import { MdInfoOutline, MdOutlineCancel } from "react-icons/md";
import Spin from "./Spin";

export default function PopularSection({
  popularApt, popularArea, loading, getPopularApt, getPopularArea
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeTab, setActiveTab] = useState("apt");

  useEffect(() => {
    if (activeTab === "apt") {
      getPopularApt();
    } else {
      getPopularArea();
    }
  }, [activeTab]);  // data를 의존성 배열에 추가하지 않음

  return (
    <section className="flex flex-col box-border m-1 p-1.5 w-full md:w-1/3">
      <div className="shadow-sm flex flex-col justify-center">
        <div className="flex justify-center items-center relative mb-3">
          <h1 className="font-bold text-lg text-center">지금 인기</h1>
          <button
            className="ml-2 text-gray-500 focus:outline-none"
            onClick={() => setShowTooltip(!showTooltip)}
          >
            <MdInfoOutline />
          </button>
          {showTooltip && (
            <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 p-3 w-64 bg-white border border-gray-300 rounded-lg shadow-md z-10">
              <div className="flex justify-between items-center mb-2">
                <h2 className="font-bold text-sm">지금 인기 아파트·지역</h2>
                <button
                  onClick={() => setShowTooltip(false)}
                  className="focus:outline-none"
                >
                  <MdOutlineCancel />
                </button>
              </div>
              <p className="text-sm text-gray-700">
                아파트, 지역 페이지 방문자수를 기준으로 아파트 순위를 제공합니다.
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-around gap-2 p-2 mb-4 bg-white rounded-full shadow-lg">
          <button
            onClick={() => setActiveTab("apt")}
            className={`text-sm px-2 py-1 border ${
              activeTab === "apt"
                ? "bg-green-600 text-white"
                : "bg-gray-100 text-green-700"}
            } rounded-full hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 text-sm`}
          >
            아파트
          </button>
          <button
            onClick={() => setActiveTab("area")}
            className={`text-sm px-2 py-1 border ${
              activeTab === "area"
                ? "bg-green-600 text-white"
                : "bg-gray-100 text-green-700"}
            } rounded-full hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 text-sm`}
          >
            지역
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Spin size={32} /><span className="ml-3 text-lg sm:text-3xl text-gray-700">데이터를 불러오는 중입니다...</span>
          </div>
        ) : activeTab === "apt" ? (
          <ul>
            {popularApt.map((apartment, index) => (
              <a
                key={apartment.complexNo}
                href={`/apartment/${apartment.complexNo}`}
              >
                <li
                  className={`flex justify-between items-center p-2 border-b ${
                    index % 2 === 0 ? "bg-[#FFFEF6]" : "bg-[#fffdee]"
                  } transition duration-300 ease-in-out hover:bg-orange-100 hover:scale-[1.01] hover:shadow-lg`}
                >
                  <span className="font-medium text-base">{index + 1}</span>
                  <div className="flex-1 ml-4">
                    <p className="font-semibold text-black text-sm sm:text-base">
                      {apartment.complexName}
                    </p>
                    <p className="text-xs sm:text-sm text-gray-500 mt-1">
                      {apartment.legalDivisionAddress}
                    </p>
                  </div>
                  <p className="font-bold text-green-600 text-sm sm:text-base">
                    {apartment.viewCount.toLocaleString()}명
                  </p>
                </li>
              </a>
            ))}
          </ul>
        ) : (
          <ul>
            {popularArea.map((area, index) => (
              <a
                key={area.id}
                href={`/area/${area.region}/${area.cityCountyDistrict}/${area.townVillageNeighborhood}`}
              >
                <li
                  className={`flex justify-between items-center p-2 border-b ${
                    index % 2 === 0 ? "bg-[#fffdfa]" : "bg-[#fffdee]"
                  } transition duration-300 ease-in-out hover:bg-orange-100 hover:scale-[1.01] hover:shadow-lg`}
                >
                  <span className="font-medium text-base">{index + 1}</span>
                  <div className="flex-1 ml-4">
                    <p className="text-black text-sm sm:text-base">
                      {area.legalDivisionAddress}
                    </p>
                  </div>
                  <p className="font-bold text-green-600 text-sm sm:text-base">
                    {area.viewCount.toLocaleString()}명
                  </p>
                </li>
              </a>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
} 