// components/TableComponent.jsx
import React, { useState, useEffect, useRef } from 'react';
import { downloadDataAsCSV, formatNumber } from '../util/helper';
import Spin from './Spin';
import { Link, useNavigate } from 'react-router-dom';
import SubwayInfoTooltip from './SubwayInfoTooltip';

function TableComponentAptMetrix({ data, columnOrder = [], onSaleClick, isLoading, error }) {
  const navigate = useNavigate();

  // Add sorting state
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending'
  });

  // Function to check if sorting is available - moved outside conditional
  const isSortingAvailable = React.useMemo(() => {
    return data && Array.isArray(data) && data.length > 0;
  }, [data]);

  // 데이터가 완전히 로드되었는지 확인
  const isDataFullyLoaded = React.useMemo(() => {
    return data && Array.isArray(data) && data.length > 0 && 
           data.every(item => item.hasOwnProperty('total_households'));
  }, [data]);

  // 데이터 형식 통일을 위한 전처리 함수
  const preprocessData = React.useMemo(() => {
    if (!data || !Array.isArray(data) || data.length === 0) return [];
    
    // Create a Set to track unique items by a suitable unique identifier (e.g., apartment_number + size_type)
    const uniqueItems = new Map();
    
    data.forEach(item => {
      const newItem = {...item};
      // 총세대수 필드를 항상 숫자로 변환
      if (newItem.total_households !== null && newItem.total_households !== undefined) {
        if (typeof newItem.total_households === 'string') {
          newItem.total_households = parseFloat(newItem.total_households.replace(/,/g, '')) || 0;
        }
      } else {
        newItem.total_households = 0; // null/undefined 값은 0으로 처리
      }
      
      // Create a unique key based on apartment_number and size_type/size_no
      const uniqueKey = `${newItem.apartment_number}_${newItem.size_type || newItem.size_no || ''}`;
      
      // Only keep the most recent/relevant entry for each unique item
      uniqueItems.set(uniqueKey, newItem);
    });
    
    // Convert the Map back to an array
    return Array.from(uniqueItems.values());
  }, [data]);

  // Apply sorting to data - moved outside conditional
  const sortedData = React.useMemo(() => {
    if (!isDataFullyLoaded) return [];
    
    // 원본 data 대신 전처리된 preprocessData 사용
    let sortableItems = [...preprocessData];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        // null 값 처리
        if (a[sortConfig.key] === null) return 1;
        if (b[sortConfig.key] === null) return -1;
        
        // 숫자 변환
        const aValue = typeof a[sortConfig.key] === 'string' && !isNaN(a[sortConfig.key].replace(/,/g, '')) 
          ? parseFloat(a[sortConfig.key].replace(/,/g, '')) 
          : a[sortConfig.key];
        const bValue = typeof b[sortConfig.key] === 'string' && !isNaN(b[sortConfig.key].replace(/,/g, '')) 
          ? parseFloat(b[sortConfig.key].replace(/,/g, '')) 
          : b[sortConfig.key];
        
        // 값이 같으면 다른 필드(예: apartment_name)를 보조 키로 사용
        if (aValue === bValue) {
          return a.apartment_name.localeCompare(b.apartment_name);
        }
        
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [preprocessData, isDataFullyLoaded, sortConfig]);

  // 총세대수 값 로깅
  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      console.log('총세대수 데이터 타입 확인:');
      data.slice(0, 5).forEach((item, index) => {
        console.log(`항목 ${index}: ${item.total_households}, 타입: ${typeof item.total_households}`);
      });
    }
  }, [data]);

  // 지하철 정보 툴팁 관련 상태 추가
  const [showSubwayTooltip, setShowSubwayTooltip] = useState(false);
  const [selectedSubwayInfo, setSelectedSubwayInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  
  // 툴팁 참조 생성
  const tooltipRef = useRef(null);
  
  // 외부 클릭 시 툴팁 닫기
  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowSubwayTooltip(false);
      }
    }
    
    // 스크롤 이벤트 발생 시 툴팁 닫기
    function handleScroll() {
      if (showSubwayTooltip) {
        console.log('scroll')
        setShowSubwayTooltip(false);
      }
    }
    
        // 툴팁이 열려있을 때 스크롤 방지
    function preventScroll(e) {
      if (showSubwayTooltip) {
        e.preventDefault();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    
    // 스크롤 방지 이벤트 리스너 추가
    if (showSubwayTooltip) {
      document.body.style.overflow = 'hidden'; // body 스크롤 비활성화
      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('touchmove', preventScroll, { passive: false });
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
      
      // 컴포넌트 언마운트 또는 툴팁 닫힐 때 스크롤 방지 해제
      document.body.style.overflow = ''; // body 스크롤 복원
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, [showSubwayTooltip]);
  
  // 지하철 정보 툴팁 표시 핸들러
  const handleShowSubwayInfo = (e, item) => {
    e.preventDefault();
    
    // 지하철 정보가 있는 경우에만 툴팁 표시
    if (item.subwaystationname) {
      setSelectedSubwayInfo(item);
      setShowSubwayTooltip(true);
      
      // 클릭 위치 기준으로 툴팁 위치 설정
      const rect = e.currentTarget.getBoundingClientRect();
      setTooltipPosition({
        x: rect.left,
        y: rect.bottom + window.scrollY
      });
    }
  };
  
  // 지하철 정보 툴팁 닫기 핸들러
  const handleCloseSubwayTooltip = () => {
    setShowSubwayTooltip(false);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Spin size={32} /><span className="ml-3 text-gray-700 text-xl md:text-3xl">데이터를 불러오는 중입니다...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center p-4">
        <p className='text-xl mb-2'>데이터를 불러오는 중 오류가 발생했습니다.</p>
        <Link to="/" className="bg-blue-50 hover:bg-blue-100 text-sm text-black font-bold py-1 px-2 rounded">
          홈으로 돌아가기
        </Link>
      </div>
    );
  }

  // Sortable columns
  const sortableColumns = [
    'exclusive_area',     // 전용면적
    'total_households',   // 총세대수
    'sale_price',         // 매매가
    'jeonse_price',       // 전세가
    'trading_gap',        // 전매갭
    'peak_comparison'     // 전고점대비
  ];

  // Sorting function
  const requestSort = (key) => {
    // Don't sort if no data is available
    if (!isSortingAvailable) return;
    
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Ensure subdistrict_name is first in the filtered column order
  const filteredColumnOrder = columnOrder.filter(column => 
    ![
      'apartment_number',
      'total_parking_spaces',
      'total_building_count',
      'floor_area_ratio',
      'building_coverage_ratio',
      'address',
      'supply_area',
      'exclusive_ratio',
      'recent_actual_transaction_floor',
      'recent_actual_transaction_formattedtradeyearmonth',
      'recent_actual_transaction_formattedprice',
      'recent_actual_transaction_representativearea',
      'recent_actual_transaction_exclusivearea',
      'schoolname',
      'studentcountperclassroom',
      'walktime',
      'organizationtype',
      'sale_count',
      'jeonse_count',
      'current_vs_peak_price',
      'previous_lowest_price_date',
      'previous_peak_price_date',
      'actual_jeonse_transaction_date',
      'actual_sale_transaction_date',
      'last_modified',
      'subdistrict_number'
    ].includes(column));
  
  // 원하는 컬럼 순서 정의
  const desiredOrder = [
    'apartment_name',      // 아파트명
    'subdistrict_name',    // 동
    'move_in_date',        // 입주일
    'exclusive_area',      // 전용면적
    'size_type',           // 평형
    'rooms_bathrooms',     // 방/욕실
    'total_households',    // 총세대수
    'household_count',     // 세대수
    'type'                 // 타입
  ];
  
  // 원하는 순서의 컬럼들을 필터링된 컬럼 목록에서 제거
  const remainingColumns = filteredColumnOrder.filter(col => 
    !desiredOrder.includes(col)
  );
  
  // 원하는 순서의 컬럼들 중 실제로 filteredColumnOrder에 존재하는 것만 선택
  const orderedColumns = desiredOrder.filter(col => 
    filteredColumnOrder.includes(col)
  );
  
  // 최종 컬럼 순서: 원하는 순서 + 나머지 컬럼들
  const finalColumnOrder = [...orderedColumns, ...remainingColumns];

  // 컬럼 이름 매핑 객체 추가
  const columnNameMapping = {
    'total_households': '총세대수',
    'move_in_date': '입주일',
    'apartment_name': '아파트명',
    'size_type': '평형',
    'subdistrict_name': '동',
    'exclusive_area': '전용면적',
    'type': '타입',
    'household_count': '세대수',
    'rooms_bathrooms': '방/욕실',
    'sale_price': '매매가',
    'jeonse_price': '전세가',
    'trading_gap': '전매갭',
    'jeonse_ratio': '전세가율',
    'actual_sale_transaction_price': '실거래매매',
    'actual_jeonse_transaction_price': '실거래전세',
    'previous_lowest_price_price': '전저점매매',
    'previous_peak_price_price': '전고점매매',
    'peak_comparison': '전고점대비',
    '매물_리스트_확인': '매물 리스트 확인'
  };

  // Function to get sort indicator
  const getSortIndicator = (column) => {
    // Don't show indicators if no data is available
    if (!isSortingAvailable) return '';
    
    if (sortConfig.key === column) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    // Show a subtle indicator for sortable columns that aren't currently sorted
    return sortableColumns.includes(column) ? ' ⋮⋮' : '';
  };

  // Add a reset function
  const resetSort = () => {
    setSortConfig({
      key: null,
      direction: 'ascending'
    });
  };

  return (
    <div className='w-full min-h-[50vh]'>
      <div className='flex justify-start md:justify-end items-center mt-2'>
        <button 
          className="mb-1 mr-2 p-1 text-xs md:text-sm border border-gray-100 bg-gray-100 hover:bg-gray-200 text-green-600 rounded" 
          onClick={resetSort} 
          disabled={!isSortingAvailable || !sortConfig.key}
          title="정렬 초기화"
        >
          정렬 초기화
        </button>
        <button 
          className="hidden lg:block mb-1 p-1 border text-xs md:text-sm border-gray-100 bg-orange-100 hover:bg-orange-200 text-black rounded underline" 
          onClick={() => downloadDataAsCSV(data, finalColumnOrder)} 
          disabled={!data}
        >
          Download CSV
        </button>
        <div className="ml-2 mb-2 p-1 lg:hidden text-xs md:text-sm text-gray-500">➡️ 왼쪽으로 스크롤하세요</div>
      </div>
      <div className="max-h-[80vh] overflow-y-auto">
        <table className='border border-collapse border-gray-100 table-auto w-full'>
          <thead className="sticky top-[-1px] z-10">
            <tr className="h-12">
              {finalColumnOrder.map((column, index) => (
                <th 
                  key={index} 
                  className={`border border-gray-200 text-left bg-[#fffdec] p-2
                    ${column === 'apartment_name' ? 'min-w-[150px] w-[150px]' : ''} 
                    ${column === 'subdistrict_name' ? 'min-w-[50px]' : ''}
                    ${column === 'type' ? 'min-w-[50px]' : ''}
                    ${sortableColumns.includes(column) && isSortingAvailable ? 'cursor-pointer hover:bg-[#f5f0d0]' : ''}`}
                  onClick={() => sortableColumns.includes(column) && isSortingAvailable ? requestSort(column) : null}
                  title={sortableColumns.includes(column) && isSortingAvailable ? '클릭하여 정렬하기' : ''}
                >
                  <div className={`text-xs md:text-sm ${column === 'apartment_name' ? 'break-words' : 'whitespace-nowrap'} 
                    overflow-hidden text-ellipsis 
                    ${sortableColumns.includes(column) && isSortingAvailable ? 'text-green-700' : ''}`}>
                    {columnNameMapping[column] || column}
                    {getSortIndicator(column)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData && sortedData.length > 0 && sortedData.map((row, rowIndex) => (
              <tr key={rowIndex} className="h-9">
                {finalColumnOrder.map((column, colIndex) => {
                  if (column === "매물_리스트_확인") {
                    const saleA1Count = row["sale_count"] || 0;
                    const saleB1Count = row["jeonse_count"] || 0;
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1'>
                        <div className="flex space-x-1">
                              <button 
                                type="button" 
                                onClick={() => {
                                  onSaleClick(row["apartment_number"], row["size_no"], false, row["size_type"]);
                                }}
                                className="p-1 border border-gray-100 bg-orange-100 hover:bg-orange-200 text-black rounded-md flex-1 text-sm whitespace-nowrap text-center underline">
                                  매매({saleA1Count})
                              </button>
                              <button 
                                type="button" 
                                onClick={() => {
                                  onSaleClick(row["apartment_number"], row["size_no"], true, row["size_type"]);
                                }}
                                className="p-1 border border-gray-100 bg-gray-100 hover:bg-gray-300 text-black rounded-md flex-1 text-sm whitespace-nowrap text-center underline">
                                  전세({saleB1Count})
                              </button>
                        </div>
                      </td>
                    );
                  } else if (column === "apartment_name") {
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm min-w-[150px] w-[150px]'>
                        <div 
                          className="break-words text-gray-600 underline hover:text-green-600 font-semibold cursor-pointer"
                          onClick={() => navigate(`/apartment/${row["apartment_number"]}`)}
                        >
                          {row[column]}
                        </div>
                      </td>
                    );
                  } else if (column === "subdistrict_name") {
                    // 동 컬럼에 지하철 정보 툴팁 추가
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>
                        {row.subwaystationname ? (
                          <div 
                            className="text-black underline hover:text-green-600 cursor-pointer"
                            onClick={(e) => handleShowSubwayInfo(e, row)}
                          >
                            {row[column]}
                          </div>
                        ) : (
                          row[column]
                        )}
                      </td>
                    );
                  } else if (column === "actual_sale_transaction_price") {
                    return (
                      <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm whitespace-nowrap">
                        <div>{row[column] !== null ? formatNumber(row[column]) : "-"}</div>
                        {row["actual_sale_transaction_date"] && (
                          <div className="text-xs text-gray-500">({row["actual_sale_transaction_date"]})</div>
                        )}
                      </td>
                    );
                  } else if (column === "actual_jeonse_transaction_price") {
                    return (
                      <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm whitespace-nowrap">
                        <div>{row[column] !== null ? formatNumber(row[column]) : "-"}</div>
                        {row["actual_jeonse_transaction_date"] && (
                          <div className="text-xs text-gray-500">({row["actual_jeonse_transaction_date"]})</div>
                        )}
                      </td>
                    );
                  } else if (column === "previous_lowest_price_price") {
                    return (
                      <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm whitespace-nowrap">
                        <div>{row[column] !== null ? formatNumber(row[column]) : "-"}</div>
                        {row["previous_lowest_price_date"] && (
                          <div className="text-xs text-gray-500">({row["previous_lowest_price_date"]})</div>
                        )}
                      </td>
                    );
                  } else if (column === "previous_peak_price_price") {
                    return (
                      <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm whitespace-nowrap">
                        <div>{row[column] !== null ? formatNumber(row[column]) : "-"}</div>
                        {row["previous_peak_price_date"] && (
                          <div className="text-xs text-gray-500">({row["previous_peak_price_date"]})</div>
                        )}
                      </td>
                    );
                  } else if (column === "peak_comparison") {
                    const percentage = row[column];
                    const priceDifference = formatNumber(row["current_vs_peak_price"]);
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>
                        <div>{percentage !== null ? `${percentage}%` : "-"}</div>
                        <div className="text-xs text-gray-500">{priceDifference}</div>
                      </td>
                    );
                  } else if (column === "전고점매매") {
                    const cellDataArray = row[column];
                    const transactionData = cellDataArray["가격"];
                    const transactionDate = cellDataArray["거래일"];
                    return (
                      <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm whitespace-nowrap">
                        <div>{transactionData}</div>
                        {transactionDate && (
                          <div className="text-xs text-gray-500">({transactionDate})</div>
                        )}
                      </td>
                    );
                  } else if (column === "전저점매매") {
                    const cellDataArray = row[column];
                    const transactionData = cellDataArray["가격"];
                    const transactionDate = cellDataArray["거래일"];
                    return (
                      <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm whitespace-nowrap">
                        <div>{transactionData}</div>
                        {transactionDate && (
                          <div className="text-xs text-gray-500">({transactionDate})</div>
                        )}
                      </td>
                    );
                  } else if (column === "전고점대비") {
                    const percentage = row[column];
                    const currentPrice = row["전고점대비현재가"];
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>
                        <div>{percentage !== null ? `${percentage}%` : ''}</div>
                        <div className="text-xs text-gray-500">{(currentPrice)}</div>
                      </td>
                    );
                  } else if (column === "sale_price") {
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>
                        {row[column] !== null ? formatNumber(row[column]) : "-"}
                      </td>
                    );
                  } else if (column === "jeonse_price") {
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>
                        {row[column] !== null ? formatNumber(row[column]) : "-"}
                      </td>
                    );
                  } else if (column === "trading_gap") {
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>
                        {row[column] !== null ? formatNumber(row[column]) : "-"}
                      </td>
                    );
                  } else if (column === "jeonse_ratio") {
                    return (
                      <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>
                        {row[column] && row[column] !== "-" ? `${formatNumber(row[column])}%` : row[column]}
                      </td>
                    );
                  } else {
                    return <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-xs md:text-sm'>{formatNumber(row[column])}</td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {/* 지하철 정보 툴팁 */}
      {showSubwayTooltip && selectedSubwayInfo && (
        <div ref={tooltipRef}>
          <SubwayInfoTooltip 
            subwayInfo={selectedSubwayInfo} 
            onClose={handleCloseSubwayTooltip}
            position={tooltipPosition}
          />
        </div>
      )}
    </div>
  );
}

export default TableComponentAptMetrix;