// src/components/Navbar.jsx
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiMenu, HiX } from 'react-icons/hi';

const Navbar = ({ session, supabase }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const linkStyle = (path) => ({
    color: location.pathname === path ? 'black' : 'gray',
    fontWeight: location.pathname === path ? '900' : 'bold',
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // 메뉴 밖 클릭 시 닫히는 로직
  const handleClickOutside = (e) => {
    // 햄버거 버튼 클릭은 무시 (버튼 클릭 시 메뉴가 닫히지 않도록)
    if (e.target.closest('.hamburger-button')) {
      return;
    }
    
    if (isMenuOpen && !e.target.closest('.mobile-menu')) {
      setIsMenuOpen(false);
    }
  };

  // 이벤트 리스너 추가
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      {/* 모바일 햄버거 메뉴 버튼 */}
      <div className="md:hidden">
        <button 
          onClick={toggleMenu}
          className="p-2 focus:outline-none hamburger-button"
        >
          {isMenuOpen ? <HiX size={24} /> : <HiMenu size={24} />}
        </button>
      </div>

      {/* 데스크탑 네비게이션 */}
      <nav className='hidden md:flex justify-evenly items-center w-full md:w-1/3'>
        <Link to="/" style={linkStyle('/')} className='text-xl p-1'>소개</Link>
        {/* <Link to="/price" style={linkStyle('/price')} className='text-xl p-1'>플랜</Link> */}
        <Link to="/popular" style={linkStyle('/popular')} className='text-xl p-1'>인기</Link>
        <Link to="/aptmetrix" style={linkStyle('/aptmetrix')} className='text-xl p-1'>AptMetrix</Link>
        <Link to="/nl2sql" style={linkStyle('/nl2sql')} className='text-xl p-1'>
          AIFinder <span className="text-xs bg-blue-500 text-white px-1 py-0.5 rounded align-top">Beta</span>
        </Link>
        {/* <Link to="/apt" style={linkStyle('/apt')} className='text-xl p-1'>아파트</Link> */}
        <Link to="/area" style={linkStyle('/area')} className='text-xl p-1'>지역별</Link>
        {/* <Link to="/my" style={linkStyle('/my')} className='text-xl p-1'>내정보</Link> */}

        {/* <Link to="/contact" style={linkStyle('/contact')} className='text-xl p-1'>문의사항</Link> */}
      </nav>

      {/* 모바일 네비게이션 메뉴 */}
      {isMenuOpen && (
        <div className="mobile-menu fixed top-0 left-0 right-0 bg-white z-50 shadow-lg">
          {/* 닫기 버튼 */}
          <div className="flex justify-end p-4">
            <button 
              onClick={toggleMenu}
              className="p-2 focus:outline-none"
            >
              <HiX size={24} />
            </button>
          </div>
          {/* 메뉴 항목 */}
          <div className="flex flex-col items-center pb-4">
            <Link 
              to="/" 
              style={linkStyle('/')} 
              className='text-xl p-3 w-full text-center'
              onClick={toggleMenu}
            >
              소개
            </Link>
            <Link 
              to="/popular" 
              style={linkStyle('/popular')} 
              className='text-xl p-3 w-full text-center'
              onClick={toggleMenu}
            >
              인기
            </Link>
            <Link 
              to="/aptmetrix" 
              style={linkStyle('/aptmetrix')} 
              className='text-xl p-3 w-full text-center'
              onClick={toggleMenu}
            >
              AptMetrix
            </Link>
            <Link 
              to="/nl2sql" 
              style={linkStyle('/nl2sql')} 
              className='text-xl p-3 w-full text-center'
              onClick={toggleMenu}
            >
              AptFinder AI <span className="text-xs bg-blue-500 text-white px-1 py-0.5 rounded ml-1 align-top">Beta</span>
            </Link>
            <Link 
              to="/area" 
              style={linkStyle('/area')} 
              className='text-xl p-3 w-full text-center'
              onClick={toggleMenu}
            >
              지역별
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
