import React, { useState, useEffect } from 'react';
import ResultOfAptMetrixContainer from '../components/ResultOfAptMetrixContainer';
import { columnOrderForAptA1B1Info, columnOrderForAptMetrix } from '../constant/columnsName';
import SaleModal from '../components/SaleModal';


function AptMetrix() {
  const [aptNo, setAptNo] = useState(null);
  const [aptMetrixInfo, setAptMetrixInfo] = useState(null);
  const [aptA1B1ListInfo, setAptA1B1ListInfo] = useState(null);
  const [isFetchingAptMetrixInfo, setIsFetchingAptMetrixInfo] = useState(false);
  const [isFetchingAptA1B1Info, setIsFetchingAptA1B1Info] = useState(false);
  const [aptMetrixInfoFetchError, setAptMetrixInfoFetchError] = useState(false);
  const [aptA1B1InfoFetchError, setAptA1B1InfoFetchError] = useState(false);


  // 모달 관련 상태 추가
  const [showSaleModal, setShowSaleModal] = useState(false);
  const [modalIsRent, setModalIsRent] = useState(false);

  // 평형 정보 수집
  const [pyeongName, setPyeongName] = useState('');
  
  // 모달이 열릴 때 body에 overflow: hidden 추가
  useEffect(() => {
    if (showSaleModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    // 컴포넌트 언마운트 시 스크롤 복원
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSaleModal]);
  
  // 지역 선택 정보 상태 추가
  const [selectedRegionInfo, setSelectedRegionInfo] = useState({
    regionCode: "",
    cityCode: "",
    regionName: "",
    cityName: "",
    searchClicked: false
  });    

  // 지역 선택 핸들러
  const handleRegionSelect = (regionData) => {
    setSelectedRegionInfo(regionData);
    
    // 검색 버튼이 클릭된 경우에만 API 호출
    if (regionData.searchClicked) {
      getAptMetrixInfo(regionData);
    }
  };

  const getAptListA1B1Info = (apt_no, pyeongNo, isRent) => {
    setIsFetchingAptA1B1Info(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/apt_sale_info`;
    // const apiUrl = 'http://127.0.0.1:5001/apt_sale_info';
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ apt_no, pyeongNo, isRent }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((aptA1B1Info) => {
        setIsFetchingAptA1B1Info(false);
        setAptA1B1ListInfo(aptA1B1Info);
        setAptA1B1InfoFetchError(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setAptA1B1InfoFetchError(true);
        setAptA1B1ListInfo(null);
        setIsFetchingAptA1B1Info(false);
      });
  };

  const getAptMetrixInfo = (regionInfo = selectedRegionInfo) => {
    setIsFetchingAptMetrixInfo(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/aptmetrix`;
    // const apiUrl = 'http://127.0.0.1:5001/aptmetrix';
    
    // API 요청 본문 구성
    const requestBody = {
      data: { 
        regionCode: regionInfo.regionCode || "",
        regionName: regionInfo.regionName || "",
        cityCode: regionInfo.cityCode || "",
        cityName: regionInfo.cityName || ""
      }
    };
    
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((aptMetrixInfoData) => {
        setIsFetchingAptMetrixInfo(false);
        setAptMetrixInfo(aptMetrixInfoData);
        setAptMetrixInfoFetchError(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setAptMetrixInfoFetchError(true);
        setAptMetrixInfo(null);
        setIsFetchingAptMetrixInfo(false);
      });
  };

  // 버튼 클릭 시 모달을 열고, 해당 매물 정보를 불러옴
  const handleSaleClick = (complexNo, pyeongNo, isRent, pyeongName) => {
    setAptNo(complexNo);
    setModalIsRent(isRent);
    getAptListA1B1Info(complexNo, pyeongNo, isRent);
    setShowSaleModal(true);
    setPyeongName(pyeongName);
  };

  return (
    <div className="m-1 p-1.5 max-w-full overflow-x-hidden">
      <ResultOfAptMetrixContainer 
        isFetchingAptMetrixInfo={isFetchingAptMetrixInfo}
        aptMetrixInfoFetchError={aptMetrixInfoFetchError}
        aptMetrixInfo={aptMetrixInfo}
        columnOrderForAptMetrix={columnOrderForAptMetrix}
        onSaleClick={handleSaleClick}
        onRegionSelect={handleRegionSelect}
      />

      <SaleModal 
        showSaleModal={showSaleModal}
        modalIsRent={modalIsRent}
        setShowSaleModal={setShowSaleModal}
        isFetchingAptA1B1Info={isFetchingAptA1B1Info}
        aptA1B1InfoFetchError={aptA1B1InfoFetchError}
        aptA1B1ListInfo={aptA1B1ListInfo}
        columnOrderForAptA1B1Info={columnOrderForAptA1B1Info}
        aptNo={aptNo}
        pyeongName={pyeongName}
      />
    </div>
  );
}

export default AptMetrix; 