import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ResultOfApartmentContainer from '../components/ResultOfApartmentContainer';
import SearchAreaContainer from '../components/SearchAreaContainer';
import { columnOrderForAptInfo, columnOrderForAptA1B1Info } from '../constant/columnsName';
import cityCodesData from '../constant/city_codes.json'; // Assume we have converted the CSV to JSON

export default function SearchByArea() {
    const [isFetchingComplexes, setIsFetchingComplexes] = useState(false);
    const [complexesFetchError, setComplexesFetchError] = useState(false);
    const { region, city, town } = useParams();
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedTown, setSelectedTown] = useState('');
    const [siDoData, setSiDoData] = useState(null);
    const [siGunGuData, setSiGunGuData] = useState(null);
    const [eupMyeonDongData, setEupMyeonDongData] = useState(null);
    const [complexes, setComplexes] = useState([]);
    const [isFetchingAptA1B1Info, setIsFetchingAptA1B1Info] = useState(false);
    const [aptA1B1ListInfo, setAptA1B1ListInfo] = useState(null);
    const [aptA1B1InfoFetchError, setAptA1B1InfoFetchError] = useState(false);
    const [isFetchingAptInfo, setIsFetchingAptInfo] = useState(false);
    const [aptInfo, setAptInfo] = useState(null);
    const [aptInfoFetchError, setAptInfoFetchError] = useState(false);
    const [formData, setFormData] = useState({"aptNumber1":"", "aptNumber2":"", "aptNumber3":""});

    // JSON 데이터를 사용하여 시도 데이터 설정
    useEffect(() => {
        if (region && city && town) {
            const filteredComplexes = cityCodesData.filter(
                item =>
                    item['지역명'] === region &&
                    item['시군구명'] === city &&
                    item['읍면동명'] === town
            );
            fetchComplexes(filteredComplexes);
        }
        const uniqueRegions = [...new Map(cityCodesData.map(item => [item['지역명'], item['지역코드']])).values()]
            .map(code => ({ cortarName: cityCodesData.find(item => item['지역코드'] === code)['지역명'], cortarNo: code }));
        setSiDoData(uniqueRegions);
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            const cities = cityCodesData
                .filter(item => item['지역코드'] === selectedRegion)
                .map(item => ({ cortarName: item['시군구명'], cortarNo: item['시군구코드'] }));
            setSiGunGuData([...new Map(cities.map(city => [city.cortarNo, city])).values()]);
            setSelectedCity('');
            setSelectedTown('');
            setEupMyeonDongData([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedCity) {
            const towns = cityCodesData
                .filter(item => item['지역코드'] === selectedRegion && item['시군구코드'] === selectedCity)
                .map(item => ({ cortarName: item['읍면동명'], cortarNo: item['읍면동코드'] }));
            setEupMyeonDongData([...new Map(towns.map(town => [town.cortarNo, town])).values()]);
            setSelectedTown('');
        }
    }, [selectedCity]);

    useEffect(() => {
        if (selectedTown) {
            const filteredComplexes = cityCodesData.filter(
                item =>
                    item['지역코드'] === selectedRegion &&
                    item['시군구코드'] === selectedCity &&
                    item['읍면동코드'] === selectedTown
            );
            fetchComplexes(filteredComplexes);
        }
    }, [selectedTown]);

    const handleRegionChange = (e) => {
        setSelectedRegion(e.target.value);
    };
    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };
    const handleTownChange = (e) => {
        setSelectedTown(e.target.value);
    };
    
    
    const fetchComplexes = (cortar) => {
        setIsFetchingComplexes(true)
        setComplexes([]) // 새로운 검색 시 이전 결과 초기화
        
        if (!cortar || cortar.length === 0) {
            setIsFetchingComplexes(false)
            setComplexesFetchError(true)
            return
        }
        
        const apiUrl = `${process.env.REACT_APP_API_URL}/complexes/${cortar[0].읍면동코드}`; 
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setIsFetchingComplexes(false)
            setComplexes(data)
            setComplexesFetchError(false)
        })
        .catch((error) => {
            console.error('There was a problem with the fetchComplexes:', error);
            setIsFetchingComplexes(false)
            setComplexesFetchError(true)
            setComplexes([])
        })
    };

    const getAptListA1B1Info = (apt_no, pyeongNo, isRent) => {
        setIsFetchingAptA1B1Info(true)
        const apiUrl = `${process.env.REACT_APP_API_URL}/apt_sale_info`; 
        fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ apt_no, pyeongNo, isRent }),
        })
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((aptA1B1Info) => {
            setIsFetchingAptA1B1Info(false)
            setAptA1B1ListInfo(aptA1B1Info)
            setAptA1B1InfoFetchError(false)
            })
            .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            setAptA1B1InfoFetchError(true)
            setAptA1B1ListInfo(null)
            })
        }

        const getAptInfo = (apt_no) => {
            setIsFetchingAptInfo(true)
            const apiUrl = `${process.env.REACT_APP_API_URL}/apt`; 
            const bodyData = apt_no ? {"aptNumber1": apt_no, "aptNumber2":"", "aptNumber3":""} : formData
            fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: bodyData }),
            })
            .then((response) => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((aptInfo) => {
                setIsFetchingAptInfo(false)
                setAptInfo(aptInfo)
                setAptInfoFetchError(false)
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                setAptInfoFetchError(true)
                setAptInfo(null)
            })
        }

    return (
        <>
            <SearchAreaContainer
            region={region}
            city={city}
            town={town}
            selectedRegion={selectedRegion}
            siDoData={siDoData}
            handleRegionChange={handleRegionChange}
            selectedCity={selectedCity}
            siGunGuData={siGunGuData}
            handleCityChange={handleCityChange}
            selectedTown={selectedTown}
            eupMyeonDongData={eupMyeonDongData}
            handleTownChange={handleTownChange}
            isFetchingComplexes={isFetchingComplexes}
            complexesFetchError={complexesFetchError}
            complexes={complexes}
            setFormData={setFormData}
            getAptInfo={getAptInfo}
            />
            <ResultOfApartmentContainer
            isFetchingAptInfo={isFetchingAptInfo} 
            aptInfoFetchError={aptInfoFetchError}
            aptInfo={aptInfo}
            columnOrderForAptInfo={columnOrderForAptInfo}
            getAptListA1B1Info={getAptListA1B1Info}
            isFetchingAptA1B1Info={isFetchingAptA1B1Info}
            aptA1B1InfoFetchError={aptA1B1InfoFetchError}
            aptA1B1ListInfo={aptA1B1ListInfo}
            columnOrderForAptA1B1Info={columnOrderForAptA1B1Info}
            />
        </>
    )
}