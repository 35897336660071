// components/ApartmentInfoContainer.jsx
import React from 'react';
import { formatNumber } from '../util/helper';

function ApartmentInfoContainer({ data }) {
  if (!data || data.length === 0) return null;

  const commonInfo = {
    complexName: data[0]['apartment_name'],
    complexNo: data[0]['apartment_number'],
    totalHouseholds: data[0]['total_households'],
    parkingPossibleCount: data[0]['total_parking_spaces'],
    dealCount: data[0]['deal_count'],
    leaseCount: data[0]['lease_count'],
    highFloor: data[0]['highest_floor'],
    lowFloor: data[0]['lowest_floor'],
    totalBuildings: data[0]['building_coverage_ratio'],
    moveInDate: data[0]['move_in_date'],
    floorAreaRatio: data[0]['floor_area_ratio'],
    buildingCoverageRatio: data[0]['building_coverage_ratio'],
    address: data[0]['address'],
    dealPricesRange: data[0]['sale_price_high'] 
      ? `${data[0]['sale_price_low']} ~ ${data[0]['sale_price_high']}` 
      : "-",
    leasePricesRange: data[0]['jeonse_price_high']
      ? `${data[0]['jeonse_price_low']} ~ ${data[0]['jeonse_price_high']}`
      : "-",
    latestDealInfo: {
      '최근실거래가격': data[0]['recent_actual_transaction_formattedprice'] 
        ? `${formatNumber(data[0]['recent_actual_transaction_formattedprice'])}`
        : "-",
      '최근실거래일': data[0]['recent_actual_transaction_formattedtradeyearmonth']
        ? `${data[0]['recent_actual_transaction_formattedtradeyearmonth']}`
        : "-",
      '최근실거래층': data[0]['recent_actual_transaction_floor'] ? `${data[0]['recent_actual_transaction_floor']}` : "-",
      '최근실거래공급/전용면적': data[0]['recent_actual_transaction_representativearea'] ? `${data[0]['recent_actual_transaction_representativearea']}/${data[0]['recent_actual_transaction_exclusivearea']}` : "-"
    }
  };

  return (
    <>
      <div className="w-full bg-[#FFFEF6] text-gray-800 rounded-lg p-2 border border-gray-200">
        <div className="flex items-baseline gap-2 flex-wrap">
          <h2 className="text-xl md:text-2xl font-bold text-gray-800">
            <a 
              href={`https://new.land.naver.com/complexes/${commonInfo.complexNo}?a=APT:PRE&e=RETAIL&ad=true`} 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-800 underline font-semibold hover:text-green-600"
            >
              {commonInfo.complexName}
            </a> 
          </h2>
          <span className="text-sm md:text-base text-gray-600">(주소: {commonInfo.address})</span>
          <span className="text-sm md:text-base text-gray-600">(입주시점: {commonInfo.moveInDate})</span>
        </div>
        
        <div className="flex flex-col md:flex-row w-full bg-[#FFFEF6] text-gray-800 rounded-lg border border-gray-200 mt-2">
          <div className="w-full md:w-1/2 p-1">
            <h4 className="text-[16px] font-bold mb-2">아파트 정보</h4>
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse text-xs md:text-base">
                <thead>
                  <tr className='bg-[#fffdec]'>
                    <th className="border p-1 text-left">아파트번호</th>
                    <th className="border p-1 text-left">총세대수</th>
                    <th className="border p-1 text-left">총 동수</th>
                    <th className="border p-1 text-left">총주차대수</th>
                    <th className="border p-1 text-left">고층/저층</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td className="border p-1">
                        <a 
                          href={`https://new.land.naver.com/complexes/${commonInfo.complexNo}?a=APT:PRE&e=RETAIL&ad=true`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-gray-800 underline font-semibold hover:text-green-600"
                        >
                          {commonInfo.complexNo}
                        </a>
                      </td>
                      <td className="border p-1">
                        {commonInfo.totalHouseholds}
                      </td>
                      <td className="border p-1">
                        {commonInfo.totalBuildings}
                      </td>
                      <td className="border p-1">
                        {commonInfo.parkingPossibleCount}
                      </td>
                      <td className="border p-1">
                        {commonInfo.highFloor}/{commonInfo.lowFloor}
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-1">
            <h4 className="text-[16px] font-bold mb-2">매매 정보</h4>
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse text-xs md:text-base">
                <thead>
                  <tr className='bg-[#fffdec]'>
                    <th className="border p-1 text-left">매매 매물 수</th>
                    <th className="border p-1 text-left">전세 매물 수</th>
                    <th className="border p-1 text-left">매매가</th>
                    <th className="border p-1 text-left">전세가</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border p-1">{commonInfo.dealCount}</td>
                    <td className="border p-1">{commonInfo.leaseCount}</td>
                    <td className="border p-1">{commonInfo.dealPricesRange}</td>
                    <td className="border p-1">{commonInfo.leasePricesRange}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row w-full bg-[#FFFEF6] text-gray-800 rounded-lg border border-gray-200 mt-2">
          <div className="w-full md:w-1/3 p-1">
            <h4 className="text-[16px] font-bold mb-2">최근 매매 실거래</h4>
            <div className="overflow-x-auto">
              {Object.keys(commonInfo.latestDealInfo).length > 0 ? (
                <table className="min-w-full border-collapse text-xs md:text-base">
                  <thead>
                    <tr className='bg-[#fffdec]'>
                      <th className="border p-1 text-left text-red-600">최근실거래가</th>
                      <th className="border p-1 text-left">최근실거래일</th>
                      <th className="border p-1 text-left">최근실거래층</th>
                      <th className="border p-1 text-left">최근실거래공급/전용면적</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td className="border p-1 text-red-600">
                          {commonInfo.latestDealInfo["최근실거래가격"]}
                        </td>
                        <td className="border p-1">
                          {commonInfo.latestDealInfo["최근실거래일"]}
                        </td>
                        <td className="border p-1">
                          {commonInfo.latestDealInfo["최근실거래층"]}
                        </td>
                        <td className="border p-1">
                          {commonInfo.latestDealInfo["최근실거래공급/전용면적"]}
                        </td>
                      </tr>
                  </tbody>
                </table>
              ) : (
                <div>최근 매매 실거래 정보가 없습니다.</div>
              )}
            </div>
          </div>
          <div className="w-full md:w-1/3 p-1">
            <h4 className="text-[16px] font-bold mb-2">초등학교</h4>
            <div className="overflow-x-auto">
              {data && data[0]['schoolname'] ? (
                <table className="min-w-full border-collapse text-xs md:text-base">
                  <thead>
                    <tr className='bg-[#fffdec]'>
                      <th className="border p-1 text-left">학교 이름</th>
                      <th className="border p-1 text-left">학급당 학생 수</th>
                      <th className="border p-1 text-left">도보 시간</th>
                      {/* <th className="border p-1 text-left">도보 시간(거리)</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(data) && data.length > 0 && data[0]['schoolname'] ? 
                      (() => {
                        // Split the school data by commas if they exist
                        const schoolNames = data[0]['schoolname'] ? data[0]['schoolname'].split(',') : ["-"];
                        const orgTypes = data[0]['organizationtype'] ? data[0]['organizationtype'].split(',') : Array(schoolNames.length).fill("-");
                        const studentCounts = data[0]['studentcountperclassroom'] ? data[0]['studentcountperclassroom'].split(',') : Array(schoolNames.length).fill("-");
                        const walkTimes = data[0]['walktime'] ? data[0]['walktime'].split(',') : Array(schoolNames.length).fill("-");
                        // const distances = data[0]['schooldistance'] ? data[0]['schooldistance'].split(',') : Array(schoolNames.length).fill("-");
                        
                        // Create an array of school objects
                        const schools = schoolNames.map((name, index) => ({
                          name: name.trim(),
                          organizationType: orgTypes[index] ? orgTypes[index].trim() : "-",
                          studentCount: studentCounts[index] ? studentCounts[index].trim() : "-",
                          walkTime: walkTimes[index] ? walkTimes[index].trim() : "-",
                          // distance: distances[index] ? distances[index].trim() : "-",
                          index
                        }));
                        
                        return schools.map((school) => (
                          <tr key={school.index}>
                            <td className="border p-1">
                              {school.name} {school.organizationType !== "-" && `[${school.organizationType}]`}
                            </td>
                            <td className="border p-1">{school.studentCount}</td>
                            <td className="border p-1">{school.walkTime}분</td>
                            {/* <td className="border p-1">{school.walkTime}분 ({school.distance}m)</td> */}
                          </tr>
                        ));
                      })() : null
                    }
                  </tbody>
                </table>
              ) : (
                <div>초등학교 정보가 없습니다.</div>
              )}
            </div>
          </div>
          <div className="w-full md:w-1/3 p-1">
            <h4 className="text-[16px] font-bold mb-2">지하철</h4>
            <div className="overflow-x-auto">
              {data && data.length > 0 && data[0]['subwaystationname'] ? (
                <table className="min-w-full border-collapse text-xs md:text-base">
                  <thead>
                    <tr className='bg-[#fffdec]'>
                      <th className="border p-1 text-left">역</th>
                      <th className="border p-1 text-left">노선</th>
                      <th className="border p-1 text-left">도보 시간(거리)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(() => {
                      // Parse subway data
                      const stationNames = data[0]['subwaystationname'] ? data[0]['subwaystationname'].split(',') : [];
                      const lineNames = data[0]['subwaylinename'] ? data[0]['subwaylinename'].split(',') : [];
                      const lineColors = data[0]['subwaylinecolor'] ? data[0]['subwaylinecolor'].split(',') : [];
                      const walkingTimes = data[0]['subwaywalkingtime'] ? data[0]['subwaywalkingtime'].split(',') : [];
                      const walkingDistances = data[0]['subwaywalkingdistance'] ? data[0]['subwaywalkingdistance'].split(',') : [];
                      
                      // Create an array of subway objects
                      const subways = stationNames.map((station, index) => {
                        // Handle multiple lines per station
                        const stationLines = lineNames[index] ? lineNames[index].split('|') : ["-"];
                        const stationColors = lineColors[index] ? lineColors[index].split('|') : Array(stationLines.length).fill('#888');
                        const stationTimes = walkingTimes[index] ? walkingTimes[index].split('|') : ["-"];
                        const stationDistances = walkingDistances[index] ? walkingDistances[index].split('|') : ["-"];
                        
                        return {
                          station: station.trim(),
                          lines: stationLines.map((line, i) => ({
                            name: line.trim(),
                            color: stationColors[i] ? stationColors[i].trim() : '#888',
                            time: stationTimes[i] ? stationTimes[i].trim() : "-",
                            distance: stationDistances[i] ? stationDistances[i].trim() : "-"
                          })),
                          index
                        };
                      });
                      
                      return subways.map((subway) => (
                        <tr key={subway.index}>
                          <td className="border p-1">{subway.station}</td>
                          <td className="border p-1">
                            {subway.lines.map((line, i) => (
                              <span 
                                key={i}
                                className="px-2 py-1 rounded-full text-white text-xs mr-1 mb-1 inline-block" 
                                style={{ backgroundColor: line.color }}
                              >
                                {line.name}
                              </span>
                            ))}
                          </td>
                          <td className="border p-1">
                            {subway.lines[0].time}분 ({subway.lines[0].distance}m)
                          </td>
                        </tr>
                      ));
                    })()}
                  </tbody>
                </table>
              ) : (
                <div className="text-xs md:text-base">지하철 정보가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApartmentInfoContainer;