import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import SignInUp from './SignInUp';
import { useNavigate } from 'react-router-dom';

export default function Header({ session, supabase }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <header className='box-border m-2 p-2 flex flex-row justify-between items-center'>
        <div className='flex-1 md:w-1/3 mb-0'>
          <Link to="/" className='flex justify-start items-center mb-0'>
              <img 
                src='/logo.png' 
                className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] cursor-pointer transition-transform duration-500 hover:scale-110 hover:-rotate-12' 
                alt="AptMetrix Logo"
              />
              <h1 className='text-xl md:text-2xl lg:text-4xl font-extrabold ml-2 relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-black hover:after:w-full after:transition-all after:duration-700'>Aptmetrix</h1>
          </Link>
        </div>
        
        <Navbar />
        
        <div className='flex-1 md:w-1/3 flex justify-end mb-0 relative'>
          <div className="relative">
            <div className="p-2 md:p-4 flex justify-center items-center">
                  {session ? (
                    <div className="flex flex-col md:flex-row items-center gap-1 md:gap-3">
                      <span className="text-sm md:text-base text-gray-700 font-medium truncate max-w-[80px] md:max-w-[150px]">
                        {session.user.user_metadata.email.split('@')[0]}
                      </span>
                      <p 
                        className="text-sm md:text-base text-gray-600 cursor-pointer hover:text-red-500 font-semibold"
                        onClick={async () => {
                          const { error } = await supabase.auth.signOut();
                          if (error) {
                            console.error("Error signing out:", error);
                          }
                          navigate('/');
                        }}
                      >
                        로그아웃
                      </p>
                    </div>
                  ) : (
                    <button 
                      className="text-sm md:text-base text-gray-600 cursor-pointer hover:text-green-600 font-semibold"
                      onClick={() => setShowModal(true)}
                    >
                      로그인
                    </button>
                  )}
              </div>
            </div>
        </div>
      </header>

      {showModal && (
        <div className="fixed inset-0 bg-[#FFFEF6] bg-opacity-100 flex items-center justify-center z-50">
          <div className="bg-[#FFFEF6] p-4 md:p-10 rounded-lg relative border shadow-xl w-[90%] max-w-md">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <SignInUp supabase={supabase} />
          </div>
        </div>
      )}
    </>
  );
}