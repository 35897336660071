//ComplexList.js
import React from 'react';
import ComplexCard from './ComplexCard';
import Spin from './Spin';
import { Link } from 'react-router-dom';

const ComplexList = ({ selectedRegion, selectedCity, selectedTown, complexes, setFormData, getAptInfo, isFetchingComplexes, complexesFetchError }) => {

    if (isFetchingComplexes) {
        return (
          <div className="flex justify-center items-center p-8">
            <Spin size={32} /><span className="ml-3 text-lg md:text-3xl text-gray-700">데이터를 불러오는 중입니다...</span>
          </div>
        );
      }
    
      if (complexesFetchError) {
        return (
          <div className="flex flex-col justify-center items-center p-4">
            <p className='text-base md:text-xl mb-2 text-gray-700'>데이터를 불러오는 중 오류가 발생했습니다.</p>
            <Link to="/" className="bg-blue-50 hover:bg-blue-100 text-sm text-black font-bold py-1 px-2 rounded">
              홈으로 돌아가기
            </Link>
          </div>
        );
      }

    return (
        <div className="m-2 p-2 flex flex-col">
            {
                complexes && complexes.length > 0 ? (
                    <>
                        <h1 className="text-base md:text-2xl font-semibold mb-2">아파트 Complexes 리스트</h1>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 md:gap-4">
                            {complexes.map((complex) => (
                                <ComplexCard key={complex.complexNo} complex={complex} setFormData={setFormData} getAptInfo={getAptInfo} />
                            ))}
                        </div>
                    </>
                ) : selectedRegion !== "" && selectedCity !== "" && selectedTown !== "" ? (
                    <div className="flex justify-center items-center p-4">
                        <p className="text-sm md:text-xl text-gray-700 text-center">해당 지역은 아파트가 없습니다<br/>다른 지역을 검색해주세요</p>
                    </div>
                ) : null
            }
        </div>
    );
};

export default ComplexList;
