import React from 'react';

const Spin = ({ size = 'default' }) => {
  const sizeClass = size === 'small' ? 'w-4 h-4' : 
                   size === 'large' ? 'w-10 h-10' : 
                   'w-6 h-6';

  return (
    <div className={`animate-spin rounded-full border-t-2 border-blue-500 ${sizeClass}`}></div>
  );
};

export default Spin;

