// Helper function to format numbers (remove .0 if present)
export const formatNumber = (value) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'number' && Number.isInteger(value)) {
      return value.toString();
    }
    if (typeof value === 'number' || (typeof value === 'string' && !isNaN(value))) {
      const num = parseFloat(value);
      return Number.isInteger(num) ? num.toString() : value.toString();
    }
    return value;
  };
  

export const formatPrice = (price) => {
    if (price == null) {
        return null;
    }
    const billion = Math.floor(price / 100000000);
    const million = Math.floor((price % 100000000) / 10000);
    
    if (billion > 0) {
        return `${billion}억 ${million > 0 ? `${million}` : ''}`;
    }
    return `${million}`;
};

// CSV 파일 생성 함수
export const convertArrayOfObjectsToCSV = (data, columnOrder) => {
    const headers = columnOrder.join(',');
    
    // CSV 행 데이터 준비
    const rows = data["apartment_name"]?.map((_, index) => {
        return columnOrder.map(column => {
            if (column !== '매물_리스트_확인') {
                let cell = data[column][index];
                // 데이터에 콤마나 쌍따옴표, 줄바꿈이 포함되어 있으면 쌍따옴표로 묶어주고, 쌍따옴표를 이스케이프 처리합니다.
                if ((typeof cell !== 'number') && cell && (cell.includes(',') || cell.includes('\n') || cell.includes('"'))) {
                    cell = `"${cell.replace(/"/g, '""')}"`;  // 쌍따옴표 이스케이프 처리
                }
                return cell;
            }
            return data[column][index];
        }).join(',');
    });
    return [headers, ...rows].join('\n');
};

// CSV 파일 생성 함수
export const convertArrayOfObjectsDataAsCSV = (data, columnOrder) => {
    const columnNameMapping = {
        'size_type': '평형',
        'total_parking_spaces': '주차공간',
        'exclusive_area': '전용면적',
        'type': '타입',
        'household_count': '세대수',
        'rooms_bathrooms': '방/욕실',
        'sale_price': '매매가',
        'jeonse_price': '전세가',
        'trading_gap': '전매갭',
        'jeonse_ratio': '전세가율',
        'actual_sale_transaction_price': '실거래매매',
        'actual_sale_transaction_date': '실거래매매거래일',
        'actual_jeonse_transaction_price': '실거래전세',
        'actual_jeonse_transaction_date': '실거래전세거래일',
        'previous_lowest_price_price': '전저점매매',
        'previous_lowest_price_date': '전저점매매거래일',
        'previous_peak_price_price': '전고점매매',
        'previous_peak_price_date': '전고점매매거래일',
        'peak_comparison': '전고점대비',
        'current_vs_peak_price': '전고점대비현재가',
        'apartment_name': '아파트명',
        'address': '주소',
        '매물_리스트_확인': '매물 리스트 확인',
        'subdistrict_name': '동',
        'move_in_date': '입주일',
        'total_households': '총세대수',
        'total_building_count': '총동수',
        'sale_count': '매매매물개수',
        'jeonse_count': '전세매물개수',
        'organizationType': '학교유형',
        'studentCountPerClassroom': '학생수/교실',
        'walkTime': '도보거리(분)',
        'schoolName': '학교명',
        'deal_count': '매매거래개수',
        'lease_count': '전세거래개수',
        'highest_floor': '최고층',
        'lowest_floor': '최저층',
        'sale_price_low': '매매가(최저)',
        'sale_price_high': '매매가(최고)',
        'jeonse_price_low': '전세가(최저)',
        'jeonse_price_high': '전세가(최고)',
        'detail_link': '상세페이지',
    };

    // Check if data is an array
    if (!Array.isArray(data) || data.length === 0) {
        console.error("Invalid data structure for CSV conversion");
        return columnOrder.map(col => columnNameMapping[col] || col).join(','); // Return just headers if no valid data
    }
    
    // 영어 컬럼명을 한글로 변환한 헤더 생성
    const koreanHeaders = columnOrder.map(column => columnNameMapping[column] || column);
    const headers = koreanHeaders.join(',');
    
    // CSV 행 데이터 준비 - 여기서는 원래 영어 키를 사용하여 데이터에 접근
    const rows = data.map(item => {
        // 하이퍼링크 컬럼 추가
        if (item.apartment_number) {
            // 상세페이지 링크
            item.detail_link = `=HYPERLINK("https://aptmetrix.kr/apartment/${item.apartment_number}","상세페이지")`;
        }

        return columnOrder.map(englishColumn => {
            let cell = item[englishColumn];
            
            // Handle null or undefined values
            if (cell === null || cell === undefined) {
                cell = '';
            }
            
            // Format numbers if needed
            if (typeof cell === 'number') {
                cell = formatNumber(cell);
            }
            
            // 데이터에 콤마나 쌍따옴표, 줄바꿈이 포함되어 있으면 쌍따옴표로 묶어주고, 쌍따옴표를 이스케이프 처리합니다.
            if (typeof cell === 'string' && (cell.includes(',') || cell.includes('\n') || cell.includes('"'))) {
                cell = `"${cell.replace(/"/g, '""')}"`;  // 쌍따옴표 이스케이프 처리
            }
            return cell;
        }).join(',');
    });
    return [headers, ...rows].join('\n');
};

// CSV 파일 다운로드 함수
export const downloadDataAsCSV = (data, columnOrder) => {
    // 기존 컬럼 추가
    columnOrder.push(
        'total_parking_spaces',
        'deal_count',
        'lease_count',
        'highest_floor',
        'lowest_floor',
        'total_building_count',
        'address',
        'previous_peak_price_date',
        'previous_lowest_price_date',
        'current_vs_peak_price',
        'sale_price_low',
        'sale_price_high',
        'jeonse_price_low',
        'jeonse_price_high',
        'schoolName',
        'studentCountPerClassroom',
        'walkTime',
        'organizationType',
        'actual_sale_transaction_date',
        'actual_jeonse_transaction_date',
        'sale_count',
        'jeonse_count'
    );
    
    // 하이퍼링크 컬럼 추가
    columnOrder.push(
        'detail_link',
    );
    
    const csvString = convertArrayOfObjectsDataAsCSV(data, columnOrder);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'apartments.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// CSV 파일(매물 리스트) 다운로드 함수
export const downloadCSV = (data, columnOrder) => {
    const csvString = convertArrayOfObjectsToCSV(data, columnOrder);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'apartments.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export function getEupmyeondongCode(regionName, districtName, townName, data) {
    for (let item of data) {
        if (
            item["지역명"] === regionName &&
            item["시군구명"] === districtName &&
            item["읍면동명"] === townName
        ) {
            return item["읍면동코드"];
        }
    }
    return null;
}
