import React, { useState, useEffect, useRef } from 'react';

const RegionDropdownMetrix = ({ 
    selectedRegion,
    selectedCity,
    onRegionChange,
    onCityChange,
    siDoData,
    siGunGuData 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeRegion, setActiveRegion] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="relative w-full md:w-auto" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`px-2 py-1 rounded-full font-bold text-green-600 bg-gray-100 focus:outline-none cursor-pointer text-xs md:text-sm hover:bg-green-600 hover:text-white min-w-[200px] w-[200px] 
                    ${selectedRegion && selectedRegion !== "" ? 'bg-green-600 text-white font-medium' : ''}`}
            >
                {selectedRegion === "" ? "지역을 선택하세요." : 
                    `${siDoData?.find(r => r.cortarNo === selectedRegion)?.cortarName}${
                        selectedCity && selectedCity !== "" && selectedRegion !== "" ? 
                        ` > ${siGunGuData?.find(c => c.cortarNo === selectedCity)?.cortarName}` : 
                        ''
                    }`
                }
            </button>

            {isOpen && (
                <div className="absolute z-50 mt-2 bg-white rounded-lg shadow-lg border border-gray-200 w-full md:w-[400px] left-0">
                    <div className="flex flex-row">
                        {/* 시/도 목록 */}
                        <div className="w-full overflow-y-auto max-h-[50vh] md:w-1/2 md:border-r md:border-gray-200">
                            {siDoData?.map((region) => (
                                <div
                                    key={region.cortarNo}
                                    className={`p-2 cursor-pointer hover:bg-gray-100 
                                        ${activeRegion === region.cortarNo ? 'bg-gray-100' : ''} text-xs md:text-sm`}
                                    onClick={() => {
                                        onRegionChange({ 
                                            target: { value: region.cortarNo },
                                            autoSearch: false
                                        });
                                        onCityChange({ target: { value: "" } });
                                        setActiveRegion(region.cortarNo);
                                        if (region.cortarNo === "") {
                                            setIsOpen(false);
                                        }
                                    }}
                                >
                                    {region.cortarName}
                                </div>
                            ))}
                        </div>

                        {/* 시/군/구 목록 */}
                        {activeRegion && activeRegion !== "" && (
                            <div className="w-full overflow-y-auto max-h-[50vh] md:w-1/2 md:border-r md:border-gray-200">
                                {siGunGuData?.map((city) => (
                                    <div
                                        key={city.cortarNo}
                                        className="p-2 cursor-pointer hover:bg-gray-100 text-xs md:text-sm"
                                        onClick={() => {
                                            onCityChange({ 
                                                target: { value: city.cortarNo },
                                                autoSearch: true
                                            });
                                            setIsOpen(false);
                                        }}
                                    >
                                        {city.cortarName}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegionDropdownMetrix; 