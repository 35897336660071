import React from 'react';
import Spin from './Spin';
import TableComponentAptMetrix from './TableComponentAptMetrix';

const ResultOfQueryContainer = ({ 
  isFetching, 
  fetchError, 
  queryResults,
  columnOrderForAptMetrix,
  onSaleClick,
  query 
}) => {
  return (
    <div className="mt-3">
      {isFetching ? (
        <div className="flex justify-center items-center p-8">
          <Spin size="large" />
          <p className="ml-3 text-gray-600">AI가 아파트를 찾고 있습니다...</p>
        </div>
      ) : (
        <TableComponentAptMetrix
          columnOrder={columnOrderForAptMetrix}
          data={queryResults}
          onSaleClick={onSaleClick}
          isLoading={isFetching} 
          error={fetchError}
        />
      )}
    </div>
  );
};

export default ResultOfQueryContainer; 