import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import Spin from './Spin';
import TableSalesComponent from './TableSalesComponent';


export default function SaleModal({ 
    showSaleModal, 
    modalIsRent, 
    setShowSaleModal, 
    isFetchingAptA1B1Info, 
    aptA1B1InfoFetchError, 
    aptA1B1ListInfo, 
    columnOrderForAptA1B1Info, 
    aptNo, 
    pyeongName 
}) {
    return (
        <>
        {/* 모달 영역 (showSaleModal === true이면 표시) */}
        {showSaleModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-2">
              <div className="bg-white rounded-lg p-3 max-h-[90vh] overflow-auto w-[95%] sm:w-3/4 md:w-3/5">
                <div className="flex justify-between items-center mb-3 sticky top-0 bg-white z-10 pb-2 border-b">
                  <div>
                    <h2 className="text-lg sm:text-xl font-semibold">
                      {modalIsRent ? "전세 매물" : "매매 매물"}
                    </h2>
                    <p className='text-xs sm:text-sm text-gray-500'>클릭하면 매물로 이동합니다</p>
                  </div>
                  <button 
                    onClick={() => setShowSaleModal(false)} 
                    className="p-1 hover:bg-gray-100 rounded-full"
                  >
                    <MdOutlineCancel size={24} />
                  </button>
                </div>
                <div className="overflow-x-auto">
                  {isFetchingAptA1B1Info ? (
                    <div className="text-center p-4 text-lg sm:text-2xl text-black">
                      <p className='flex items-center justify-center gap-2'>
                        <span className="text-black">
                          <Spin />
                        </span>
                        매물 정보를 불러오는 중입니다...
                      </p>
                    </div>
                  ) : aptA1B1InfoFetchError ? (
                    <div className="text-center text-red-500">Error loading sale info.</div>
                  ) : aptA1B1ListInfo ? (
                    <TableSalesComponent columnOrder={columnOrderForAptA1B1Info} data={aptA1B1ListInfo} pyeongName={pyeongName} aptNo={aptNo} />
                  ) : (
                    <div>매물 정보가 없습니다.</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
    );
} 