import React from 'react';

export default function PromoSections() {
  return (
    <div className="bg-[#FFFEF6]">

      {/* 8. AI 자연어 검색 */}
      <section className="min-h-screen flex items-center">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/aifinder.png" 
                alt="AI 자연어 검색" 
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              자연어로 원하는 아파트 찾기
            </h2>
            <p className="text-lg text-gray-600">
              "강남구에 있는 30억 이하, 전용면적 84㎡ 아파트 찾아줘" 처럼
              일상 언어로 검색하세요. AI가 복잡한 조건을 이해하고 
              딱 맞는 매물을 찾아드립니다.
            </p>
            <div className="mt-4 bg-blue-50 p-3 rounded-lg border border-blue-200">
              <p className="text-sm text-blue-700 flex items-center">
                <span className="font-semibold mr-1">Beta</span> 
                - 더 나은 검색 경험을 위해 계속 발전하고 있습니다
              </p>
            </div>
          </div>
        </div>
      </section>

        {/* 7. 필터 기능 */}
      <section className="min-h-screen flex items-center">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/filter_feature.png" 
                alt="필터 기능" 
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              맞춤형 필터로 원하는 매물만
            </h2>
            <p className="text-lg text-gray-600">
              매매가 범위, 전용면적, 세대수 등 다양한 조건을 자유롭게 조절해
              딱 맞는 매물만 모아서 확인하세요.
            </p>
          </div>
        </div>
      </section>

      {/* 1. 정렬 기능 */}
      <section className="min-h-screen flex items-center">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          {/* 왼쪽 이미지 */}
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/sorting_feature.png" 
                alt="정렬 기능 예시" 
                className="w-full h-auto"
              />
            </div>
          </div>
          {/* 오른쪽 문구 */}
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              원하는 기준으로 쉽고 빠르게 정렬
            </h2>
            <p className="text-lg text-gray-600">
              전용면적, 총세대수, 매매가 등 원하는 기준을 클릭 한 번으로
              정렬하여 비교해보세요. 필요한 정보만 빠르게 찾아볼 수 있습니다.
            </p>
          </div>
        </div>
      </section>

        {/* 5. CSV 다운로드 */}
        <section className="min-h-screen flex items-center">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/download_csv.png" 
                alt="CSV 다운로드" 
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              편리한 CSV 다운로드
            </h2>
            <p className="text-lg text-gray-600">
              데이터가 필요할 땐 한 번의 클릭으로 CSV 파일을 다운로드하세요.
              다양한 정보들을 엑셀이나 다른 툴에서 편리하게 분석할 수 있습니다.
            </p>
          </div>
        </div>
      </section>

      {/* 3. 아파트 디테일 */}
      <section className="min-h-screen flex items-center">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/apartment_detail.png" 
                alt="아파트 디테일" 
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              상세 정보까지 한눈에
            </h2>
            <p className="text-lg text-gray-600">
              단지명, 면적, 입주일, 세대수 등 아파트의 핵심 정보를
              간단하게 조회하세요. 중요한 세부 사항을 놓치지 않고 모두 확인 가능합니다.
            </p>
          </div>
        </div>
      </section>

        {/* 2. 네이버 매물 확인 */}
      <section className="min-h-screen flex items-center bg-[#FFFEF6]">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/naver_listing.png" 
                alt="네이버 매물확인" 
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              네이버 부동산 매물 연동
            </h2>
            <p className="text-lg text-gray-600">
              매물 정보를 클릭하면 네이버 부동산 페이지로 바로 이동하여
              실제 매물 정보를 확인할 수 있습니다. 신속하게 위치와 매물 상태를
              파악해보세요.
            </p>
          </div>
        </div>
      </section>

      {/* 4. 실시간 매물 확인 */}
      <section className="min-h-screen flex items-center bg-[#FFFEF6]">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/realtime_listing.png" 
                alt="실시간 매물 확인" 
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              실시간 거래 매물 확인
            </h2>
            <p className="text-lg text-gray-600">
              신규 등록된 매물 정보, 가격 변동 상황을 실시간으로 보여줍니다.
              시세 변화를 놓치지 않고 바로바로 확인하세요.
            </p>
          </div>
        </div>
      </section>

     

      {/* 6. 인기/상승/거래량 */}
      <section className="min-h-screen flex items-center bg-[#FFFEF6]">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-3/5 relative mb-8 md:mb-0">
            <div className="shadow-2xl rounded-lg overflow-hidden">
              <img 
                src="/popular_rising_volume.png" 
                alt="실시간 인기, 최고상승, 거래량" 
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="md:w-2/5 md:pl-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              인기 아파트, 최고 상승, 거래량까지
            </h2>
            <p className="text-lg text-gray-600">
              지역별로 실시간 인기 아파트부터 가장 많이 오른 매물,
              거래량 순위까지 한자리에서 살펴보세요. 시장 흐름 파악에 유용합니다.
            </p>
          </div>
        </div>
      </section>

    </div>
  );
}
