import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ResultOfApartmentContainer from '../components/ResultOfApartmentContainer';
import Spin from '../components/Spin';
import { columnOrderForAptInfo, columnOrderForAptA1B1Info } from '../constant/columnsName';
import SaleModal from '../components/SaleModal';

const ApartmentDetail = () => {
  const { aptNo, pyeongNo } = useParams();
  const [aptInfo, setAptInfo] = useState(null);
  const [aptA1B1ListInfo, setAptA1B1ListInfo] = useState(null);
  const [isFetchingAptInfo, setIsFetchingAptInfo] = useState(false);
  const [isFetchingAptA1B1Info, setIsFetchingAptA1B1Info] = useState(false);
  const [aptInfoFetchError, setAptInfoFetchError] = useState(false);
  const [aptA1B1InfoFetchError, setAptA1B1InfoFetchError] = useState(false);

  // 모달 관련 상태 추가
  const [showSaleModal, setShowSaleModal] = useState(false);
  const [modalIsRent, setModalIsRent] = useState(false);

  // 평형 정보 수집
  const [pyeongName, setPyeongName] = useState('');

  // 모달이 열릴 때 body에 overflow: hidden 추가
  useEffect(() => {
    if (showSaleModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    // 컴포넌트 언마운트 시 스크롤 복원
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSaleModal]);

  // 기존 URL의 rent 파라미터는 더 이상 기본값으로 사용하지 않음
  // const isRent = searchParams.get('rent') === 'true';

  useEffect(() => {
    if (aptNo) {
      getAptInfo(aptNo);
    }
    // 기존에 aptA1B1 매물정보를 기본 호출하던 코드를 삭제:
    // if (aptNo && pyeongNo) {
    //   getAptListA1B1Info(aptNo, pyeongNo, isRent);
    // }
  }, [aptNo]);

  const getAptInfo = (apt_no) => {
    setIsFetchingAptInfo(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/apt`;
    // const apiUrl = 'http://127.0.0.1:5001/apt';
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: { aptNumber1: apt_no, aptNumber2: "", aptNumber3: "" } }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((aptInfo) => {
        setIsFetchingAptInfo(false);
        setAptInfo(aptInfo);
        setAptInfoFetchError(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setAptInfoFetchError(true);
        setAptInfo(null);
        setIsFetchingAptInfo(false);
      });
  };

  const getAptListA1B1Info = (apt_no, pyeongNo, isRent) => {
    setIsFetchingAptA1B1Info(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/apt_sale_info`;
    // const apiUrl = 'http://127.0.0.1:5001/apt_sale_info';
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ apt_no, pyeongNo, isRent }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((aptA1B1Info) => {
        setIsFetchingAptA1B1Info(false);
        setAptA1B1ListInfo(aptA1B1Info);
        setAptA1B1InfoFetchError(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setAptA1B1InfoFetchError(true);
        setAptA1B1ListInfo(null);
        setIsFetchingAptA1B1Info(false);
      });
  };

  // 버튼 클릭 시 모달을 열고, 해당 매물 정보를 불러옴
  const handleSaleClick = (complexNo, pyeongNo, isRent, pyeongName) => {
    setModalIsRent(isRent);
    getAptListA1B1Info(complexNo, pyeongNo, isRent);
    setShowSaleModal(true);
    setPyeongName(pyeongName);
  };

  if (isFetchingAptInfo) {
    return (
      <div className="flex justify-center items-center p-8">
        <Spin size={32} /><span className="ml-3 text-gray-700 text-3xl md:text-3xl text-xl">아파트 정보 불러오는 중...</span>
      </div>
    );
  }

  if (aptInfoFetchError) {
    return (
      <div className='m-1 p-2 text-center'>
        <p className='text-2xl mb-4'>아파트 정보를 찾을 수 없습니다. 올바른 아파트 번호인지 확인해주세요.</p>
        <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          홈으로 돌아가기
        </Link>
      </div>
    );
  }

  return (
    <div className='m-1 p-1.5 max-w-full'>
      <ResultOfApartmentContainer 
        isFetchingAptInfo={isFetchingAptInfo}
        aptInfoFetchError={aptInfoFetchError}
        aptInfo={aptInfo}
        columnOrderForAptInfo={columnOrderForAptInfo}
        onSaleClick={handleSaleClick}
      />

      <SaleModal 
        showSaleModal={showSaleModal}
        modalIsRent={modalIsRent}
        setShowSaleModal={setShowSaleModal}
        isFetchingAptA1B1Info={isFetchingAptA1B1Info}
        aptA1B1InfoFetchError={aptA1B1InfoFetchError}
        aptA1B1ListInfo={aptA1B1ListInfo}
        columnOrderForAptA1B1Info={columnOrderForAptA1B1Info}
        aptNo={aptNo}
        pyeongName={pyeongName}
      />
      
    </div>
  );
};

export default ApartmentDetail;