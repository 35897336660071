import React, { useState } from 'react';
import QueryInputComponent from '../components/QueryInputComponent';
import ResultOfQueryContainer from '../components/ResultOfQueryContainer';
import { columnOrderForAptA1B1Info, columnOrderForAptMetrix } from '../constant/columnsName';
import SaleModal from '../components/SaleModal';

function NL2SQL() {
    const [query, setQuery] = useState('');
    const [queryResults, setQueryResults] = useState(null);
    const [sqlQuery, setSqlQuery] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [aptNo, setAptNo] = useState(null);
    const [aptA1B1ListInfo, setAptA1B1ListInfo] = useState(null);
    const [isFetchingAptA1B1Info, setIsFetchingAptA1B1Info] = useState(false);
    const [aptA1B1InfoFetchError, setAptA1B1InfoFetchError] = useState(false);

    // 모달 관련 상태 추가
    const [showSaleModal, setShowSaleModal] = useState(false);
    const [modalIsRent, setModalIsRent] = useState(false);

      // 평형 정보 수집
    const [pyeongName, setPyeongName] = useState('');

    const handleQuerySubmit = (queryText) => {
        setQuery(queryText);
        getQueryResults(queryText);
    };

    const getQueryResults = (queryText) => {
        setIsFetching(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/nl2sql`;
        
        fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: queryText }),
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
            }
            return response.json();
        })
        .then((data) => {
            console.log(data.command , '||', data.rowCount);
            setIsFetching(false);
            setQueryResults(data.rows || []);
            setSqlQuery(data.sql || '');
            setFetchError(false);
        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            setFetchError(true);
            setQueryResults(null);
            setSqlQuery(null);
            setIsFetching(false);
        });
    };

    const getAptListA1B1Info = (apt_no, pyeongNo, isRent) => {
        setIsFetchingAptA1B1Info(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/apt_sale_info`;
        // const apiUrl = 'http://127.0.0.1:5001/apt_sale_info';
        fetch(apiUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ apt_no, pyeongNo, isRent }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((aptA1B1Info) => {
            setIsFetchingAptA1B1Info(false);
            setAptA1B1ListInfo(aptA1B1Info);
            setAptA1B1InfoFetchError(false);
          })
          .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            setAptA1B1InfoFetchError(true);
            setAptA1B1ListInfo(null);
            setIsFetchingAptA1B1Info(false);
          });
      };

        // 버튼 클릭 시 모달을 열고, 해당 매물 정보를 불러옴
        const handleSaleClick = (complexNo, pyeongNo, isRent, pyeongName) => {
            setAptNo(complexNo);
            setModalIsRent(isRent);
            getAptListA1B1Info(complexNo, pyeongNo, isRent);
            setShowSaleModal(true);
            setPyeongName(pyeongName);
        };

    return (
        <div className="m-1 p-1.5 max-w-full overflow-x-hidden">
        <p className="text-gray-600 mb-2">인공지능으로 쉽게 원하는 아파트 정보를 찾아보세요. 자연어로 질문하면 AI가 결과를 찾아드립니다.</p>
        <QueryInputComponent onQuerySubmit={handleQuerySubmit} />
        
        {sqlQuery && (
            <div className="mt-4 p-4 bg-gray-100 rounded-lg">
            <h2 className="font-semibold mb-2">생성된 검색 쿼리:</h2>
            <pre className="bg-gray-800 text-white p-3 rounded overflow-x-auto">{sqlQuery}</pre>
            </div>
        )}
        
        <ResultOfQueryContainer 
            isFetching={isFetching}
            fetchError={fetchError}
            queryResults={queryResults}
            columnOrderForAptMetrix={columnOrderForAptMetrix}
            onSaleClick={handleSaleClick}
            query={query}
        />

        <SaleModal 
            showSaleModal={showSaleModal}
            modalIsRent={modalIsRent}
            setShowSaleModal={setShowSaleModal}
            isFetchingAptA1B1Info={isFetchingAptA1B1Info}
            aptA1B1InfoFetchError={aptA1B1InfoFetchError}
            aptA1B1ListInfo={aptA1B1ListInfo}
            columnOrderForAptA1B1Info={columnOrderForAptA1B1Info}
            aptNo={aptNo}
            pyeongName={pyeongName}
        />
        </div>
    );
}

export default NL2SQL; 