import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../util/supabaseClient';

function Success({ session }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  useEffect(() => {
    async function verifySubscription() {
      if (!session?.user) return;
      
      setLoading(true);
      
      try {
        // URL의 query string에서 session_id를 가져옴
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');
        console.log('결제 완료 sessionId:', sessionId);
        
        if (!sessionId) {
          console.error('세션 ID가 없습니다');
          return;
        }

        // 1. 먼저 Supabase에서 구독 정보 확인
        const { data: subscriptionData, error: subscriptionError } = await supabase
          .from('subscriptions')
          .select('*')
          .eq('user_id', session.user.id)
          .single();
        
        if (subscriptionError && subscriptionError.code !== 'PGRST116') { // PGRST116: 결과가 없음
          console.error('구독 정보 조회 오류:', subscriptionError);
        }
        
        // 구독 정보가 이미 있으면 상태 업데이트
        if (subscriptionData) {
          setSubscriptionStatus(subscriptionData);
        } else {
          // 2. 구독 정보가 없으면 서버에 확인 요청
          // 서버에서 Stripe API를 통해 세션 정보를 확인하고 DB에 저장하는 엔드포인트
          const response = await fetch(`${process.env.REACT_APP_API_URL}/verify-subscription`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              sessionId,
              userId: session.user.id
            }),
          });
          
          if (!response.ok) {
            throw new Error('구독 확인 실패');
          }
          
          const result = await response.json();
          setSubscriptionStatus(result.subscription);
        }
      } catch (err) {
        console.error('구독 확인 중 오류 발생:', err);
      } finally {
        setLoading(false);
      }
    }

    verifySubscription();
  }, [location.search, session]);

  // 사용자가 미로그인 상태라면 돌아가기
  if (!session?.user) {
    navigate('/');
    return null;
  }

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-12 px-4 text-center">
        <p className="text-xl">구독 정보를 확인 중입니다...</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-12 px-4">
      <div className="bg-white shadow-lg rounded-lg p-8 text-center">
        <h2 className="text-3xl font-bold text-green-600 mb-4">결제가 완료되었습니다!</h2>
        <p className="text-xl text-gray-700 mb-6">
          구독이 성공적으로 활성화되었습니다.
        </p>

        {subscriptionStatus && (
          <div className="mb-6 p-4 bg-gray-50 rounded-lg">
            <p className="font-medium">구독 정보</p>
            <p>플랜: {subscriptionStatus.plan_name}</p>
            <p>상태: {subscriptionStatus.active ? '활성' : '비활성'}</p>
          </div>
        )}

        <button 
          onClick={() => navigate('/my')}
          className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
        >
          내 계정으로 이동
        </button>
      </div>
    </div>
  );
}

export default Success;
