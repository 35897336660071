import React, { useState, useEffect, useRef } from 'react';

const RegionDropdown = ({ selectedRegion, selectedCity, onRegionChange, onCityChange, siDoData, siGunGuData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeRegion, setActiveRegion] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`px-2 py-1 rounded-full font-bold text-green-600 bg-gray-100 focus:outline-none cursor-pointer text-sm hover:bg-green-600 hover:text-white w-full min-w-[190px] w-[190px] 
                    ${selectedRegion && selectedRegion !== "" ? 'min-w-[250px] bg-green-600 text-white font-medium' : ''}`}
            >
                {selectedRegion === "" ? "전국" : 
                    `${siDoData?.find(r => r.cortarNo === selectedRegion)?.cortarName}${
                        selectedCity && selectedCity !== "" && selectedRegion !== "" ? 
                        ` > ${siGunGuData?.find(c => c.cortarNo === selectedCity)?.cortarName}` : 
                        ''
                    }`
                }
            </button>

            {isOpen && (
                <div className="absolute z-50 mt-2 bg-white rounded-lg shadow-lg border border-gray-200 w-[320px]">
                    <div className="flex flex-row max-h-[60vh] overflow-y-auto">
                        {/* 시/도 목록 */}
                        <div className="w-full sm:w-1/2 border-b sm:border-b-0 sm:border-r border-gray-200">
                            {siDoData?.map((region) => (
                                <div
                                    key={region.cortarNo}
                                    className={`px-4 py-2 cursor-pointer hover:bg-gray-100 
                                        ${activeRegion === region.cortarNo ? 'bg-gray-100' : ''} text-sm`}
                                    onClick={() => {
                                        onRegionChange({ 
                                            target: { value: region.cortarNo },
                                            autoSearch: true
                                        });
                                        onCityChange({ target: { value: "" } });
                                        setActiveRegion(region.cortarNo);
                                        if (region.cortarNo === "") {
                                            setIsOpen(false);
                                        }
                                    }}
                                >
                                    {region.cortarName}
                                </div>
                            ))}
                        </div>

                        {/* 시/군/구 목록 */}
                        {activeRegion && activeRegion !== "" && (
                            <div className="w-full sm:w-1/2 max-h-[60vh] overflow-y-auto">
                                {siGunGuData?.map((city) => (
                                    <div
                                        key={city.cortarNo}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-sm"
                                        onClick={() => {
                                            onCityChange({ 
                                                target: { value: city.cortarNo },
                                                autoSearch: true
                                            });
                                            setIsOpen(false);
                                        }}
                                    >
                                        {city.cortarName}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegionDropdown; 