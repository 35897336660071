// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import SearchApt from './pages/SearchApt';
import Popular from './pages/Popular';
import Home from './pages/Home';
// import Diff from './pages/Diff';
// import Contact from './pages/Contact';
import SearchByArea from './pages/SearchByArea';
import ApartmentDetail from './pages/ApartmentDetail';
import { useState, useEffect } from 'react'
import Footer from './components/Footer';
import SignInUp from './components/SignInUp';
import MyInfo from './pages/MyInfo';
import Price from './pages/Price';
import AptMetrix from './pages/AptMetrix';
import NL2SQL from './pages/NL2SQL';
import { supabase } from './util/supabaseClient';
import GoogleLoginButton from './components/GoogleLoginButton';
import Success from './components/Success';
import Cancel from './components/Cancel';
import ProtectedRoute from './components/ProtectedRoute';


function App() {
  const [session, setSession] = useState(null)
  const [routeError, setRouteError] = useState(false)

  useEffect(() => {
    // 현재 세션 정보를 가져오고, 세션이 바뀔 때마다 상태를 업데이트
    supabase.auth.getSession().then(({ data }) => {
      setSession(data.session);
    });

    // 세션 변경 이벤트
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, [])

  // Route 에러 발생 시 root path로 리다이렉트
  useEffect(() => {
    if (routeError) {
      window.location.href = '/';
    }
  }, [routeError]);

  return (
    <Router>
      <div className="App">
        <Header session={session} supabase={supabase} />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/pilot" element={<Diff />} /> */}
          <Route path="/popular" element={<ProtectedRoute element={<Popular />} session={session} />} />
          {/* <Route path="/apt" element={<SearchApt />} /> */}
          <Route path="/aptmetrix" element={<ProtectedRoute element={<AptMetrix />} session={session} />} />
          <Route path="/nl2sql" element={<ProtectedRoute element={<NL2SQL />} session={session} />} />
          <Route path="/area" element={<ProtectedRoute element={<SearchByArea />} session={session} />} />
          {/* <Route path="/my" element={<MyInfo session={session} />} />
          <Route path="/price" element={<Price session={session} />} /> */}
          <Route path="/area/:region/:city/:town" element={<ProtectedRoute element={<SearchByArea />} session={session} />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path="/apartment/:aptNo" element={<ProtectedRoute element={<ApartmentDetail />} session={session} />} />
          {/* <Route path="/apartment/:aptNo/:pyeongNo" element={<ApartmentDetail />} /> */} 
          <Route path="/sign" element={<SignInUp supabase={supabase} />} />
          <Route path="/success" element={session?.user ? <Success session={session} /> : <Home />} />
          <Route path="/cancel" element={<Cancel />} />
          {/* 404 페이지 및 에러 처리를 위한 fallback 라우트 */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <hr />
        <Footer />
      </div>
    </Router>
  )
}

export default App;
