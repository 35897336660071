// components/ResultOfApartmentContainer.jsx
import React from 'react';
import Spin from './Spin';
import TableComponent from './TableComponent';
import ApartmentInfoContainer from './ApartmentInfoContainer';

export default function ResultOfApartmentContainer({ 
  isFetchingAptInfo, 
  aptInfoFetchError, 
  aptInfo, 
  columnOrderForAptInfo,
  onSaleClick
}) {
  return (
    <>
      {isFetchingAptInfo && !aptInfoFetchError ? (
        <div className='mx-2 my-1 p-2 flex justify-center'>
          <button disabled type="button" className="text-white bg-blue-500 font-semibold text-sm sm:text-xl rounded-lg px-3 py-2 text-center inline-flex items-center">
            <Spin size={24} /><span className="ml-2 text-base sm:text-xl text-gray-700">데이터를 불러오는 중...</span>
          </button>
        </div>
      ) : aptInfo && (
        <>
          <ApartmentInfoContainer data={aptInfo} />
          <TableComponent 
          columnOrder={columnOrderForAptInfo} 
          data={aptInfo} 
          onSaleClick={onSaleClick} 
          isLoading={isFetchingAptInfo}
          error={aptInfoFetchError}
          />
        </>
      )}
      {aptInfoFetchError && (
        <div className='mx-2 my-1 p-2'>
          <p className='text-3xl'>Something Wrong with apt info....</p>
        </div>
      )}
    </>
  );
}