// components/TableComponent.jsx
import React from 'react';
import Spin from './Spin';
import { Link } from 'react-router-dom';
import { formatNumber } from '../util/helper';


function TableComponent({ data, columnOrder = [], onSaleClick, isLoading, error }) {
  if (!data || !data.length) return null;
  
  const filteredColumnOrder = columnOrder.filter(column => 
    !['apartment_name',
      'apartment_number',
      'total_households',
      'total_parking_spaces',
      'total_building_count',
      'move_in_date',
      'floor_area_ratio',
      'building_coverage_ratio',
      'address',
      'supply_area',
      'exclusive_ratio',
      'recent_actual_transaction_floor',
      'recent_actual_transaction_formattedtradeyearmonth',
      'recent_actual_transaction_formattedprice',
      'recent_actual_transaction_representativearea',
      'recent_actual_transaction_exclusivearea',
      'schoolname',
      'studentcountperclassroom',
      'walktime',
      'organizationtype',
      'sale_count',
      'jeonse_count',
      'current_vs_peak_price',
      'previous_lowest_price_date',
      'previous_peak_price_date',
      'actual_jeonse_transaction_date',
      'actual_sale_transaction_date'
      ].includes(column));

  // 컬럼 이름 매핑 객체 추가
  const columnNameMapping = {
    'size_type': '평형',
    'exclusive_area': '전용면적',
    'type': '타입',
    'household_count': '세대수',
    'rooms_bathrooms': '방/욕실',
    'sale_price': '매매가',
    'jeonse_price': '전세가',
    'trading_gap': '전매갭',
    'jeonse_ratio': '전세가율',
    'actual_sale_transaction_price': '실거래매매',
    'actual_jeonse_transaction_price': '실거래전세',
    'previous_lowest_price_price': '전저점매매',
    'previous_peak_price_price': '전고점매매',
    'peak_comparison': '전고점대비',
    '매물_리스트_확인': '매물 리스트 확인'
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Spin size={32} /><span className="ml-3 text-gray-700 text-3xl">데이터를 불러오는 중입니다...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center p-4">
        <p className='text-xl mb-2'>데이터를 불러오는 중 오류가 발생했습니다.</p>
        <Link to="/" className="bg-blue-50 hover:bg-blue-100 text-sm text-black font-bold py-1 px-2 rounded">
          홈으로 돌아가기
        </Link>
      </div>
    );
  }

  return (
    <div className='overflow-x-auto w-full'>
      <div className='flex justify-start md:justify-end items-center mt-2'>
      <div className="mb-1 p-1 lg:hidden text-xs md:text-sm text-gray-500">➡️ 왼쪽으로 스크롤하세요</div>

      </div>
      <table className='border border-collapse border-gray-100 table-auto w-full text-[10px] xs:text-xs sm:text-sm'>
        <thead>
          <tr>
            {filteredColumnOrder.map((column, index) => (
              <th key={index} 
                className={`border border-collapse border-gray-100 text-left bg-[#fffdec] p-1
                ${column === 'type' ? 'min-w-[50px]' : ''}`}
              >
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                  {columnNameMapping[column] || column}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {filteredColumnOrder.map((column, colIndex) => {
                if (column === "매물_리스트_확인") {
                  const saleA1Count = row["sale_count"] || 0;
                  const saleB1Count = row["jeonse_count"] || 0;
                  return (
                    <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1'>
                      <div className="flex space-x-1">
                            <button 
                              type="button" 
                              onClick={() => onSaleClick(row["apartment_number"], row["size_no"], false, row["size_type"])}
                              className="p-1 border border-gray-100 bg-orange-100 hover:bg-orange-200 text-black rounded-md flex-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap text-center underline">
                                매매({saleA1Count})
                            </button>
                            <button 
                              type="button" 
                              onClick={() => onSaleClick(row["apartment_number"], row["size_no"], true, row["size_type"])}
                              className="p-1 border border-gray-100 bg-blue-100 hover:bg-blue-200 text-black rounded-md flex-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap text-center underline">
                                전세({saleB1Count})
                            </button>
                      </div>
                    </td>
                  );
                } else if (column === "actual_sale_transaction_price") {
                  return (
                    <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap">
                      <div>{formatNumber(row[column])}</div>
                      {row["actual_sale_transaction_date"] && (
                        <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">({row["actual_sale_transaction_date"]})</div>
                      )}
                    </td>
                  );
                } else if (column === "actual_jeonse_transaction_price") {
                  return (
                    <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap">
                      <div>{formatNumber(row[column])}</div>
                      {row["actual_jeonse_transaction_date"] && (
                        <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">({row["actual_jeonse_transaction_date"]})</div>
                      )}
                    </td>
                  );
                } else if (column === "previous_lowest_price_price") {
                  return (
                    <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap">
                      <div>{formatNumber(row[column])}</div>
                      {row["previous_lowest_price_date"] && (
                        <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">({row["previous_lowest_price_date"]})</div>
                      )}
                    </td>
                  );
                } else if (column === "previous_peak_price_price") {
                  return (
                    <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap">
                      <div>{formatNumber(row[column])}</div>
                      {row["previous_peak_price_date"] && (
                        <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">({row["previous_peak_price_date"]})</div>
                      )}
                    </td>
                  );
                } else if (column === "peak_comparison") {
                  const percentage = row[column];
                  const priceDifference = row["current_vs_peak_price"];
                  return (
                    <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm'>
                      <div>{percentage !== null ? `${formatNumber(percentage)}%` : ''}</div>
                      <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">{formatNumber(priceDifference)}</div>
                    </td>
                  );
                } else if (column === "전고점매매") {
                  const cellDataArray = row[column];
                  const transactionData = cellDataArray?.["가격"];
                  const transactionDate = cellDataArray?.["거래일"];
                  return (
                    <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap">
                      <div>{transactionData}</div>
                      {transactionDate && (
                        <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">({transactionDate})</div>
                      )}
                    </td>
                  );
                } else if (column === "전저점매매") {
                  const cellDataArray = row[column];
                  const transactionData = cellDataArray?.["가격"];
                  const transactionDate = cellDataArray?.["거래일"];
                  return (
                    <td key={colIndex} className="border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm whitespace-nowrap">
                      <div>{transactionData}</div>
                      {transactionDate && (
                        <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">({transactionDate})</div>
                      )}
                    </td>
                  );
                } else if (column === "전고점대비") {
                  const percentage = row[column];
                  const currentPrice = row["전고점대비현재가"];
                  return (
                    <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm'>
                      <div>{percentage !== null ? `${formatNumber(percentage)}%` : ''}</div>
                      <div className="text-[8px] xs:text-[10px] sm:text-xs text-gray-500">{formatNumber(currentPrice)}</div>
                    </td>
                  );
                } else if (column === "매매가" || column === "전세가" || column === "전매갭") {
                  return (
                    <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm'>
                      {formatNumber(row[column])}
                    </td>
                  );
                } else {
                  return <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1 text-[10px] xs:text-xs sm:text-sm'>{formatNumber(row[column])}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;