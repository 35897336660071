// components/TableSalesComponent.jsx
import React, { useState, useEffect } from 'react';

function TableSalesComponent({ data, columnOrder, pyeongName, aptNo }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!data) return null;
  
  const filteredColumnOrder = columnOrder.filter(column => 
    !['complexName', 'complexNo', '총세대수', '아프트_총_동수', '입주시점', '용적률', '건폐율', '주소', '공급면적', '전용면적', '거래방식', 'atclNo'].includes(column) && 
    !(isMobile && column === 'Tag')
  );

  const handleRowClick = (data, rowIndex) => {
    const atclNo = data["atclNo"][rowIndex];
    const url = `https://new.land.naver.com/complexes/${aptNo}?a=APT&e=RETAIL&ad=true&articleNo=${atclNo}`;
    window.open(url, '_blank');
  };

  return (
    <>
        <div className=''>
          <div className="flex justify-center items-center gap-2 ">
            <h3 className="text-xl md:text-2xl font-bold mb-2">
              {data["complexName"][0]}
            </h3>
            <span className="hidden md:inline text-sm md:text-base text-gray-600">({pyeongName}평형)</span>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className='border border-collapse border-gray-100 table-auto w-full text-xs md:text-sm'>
            <thead>
              <tr>
                {filteredColumnOrder.map((column, index) => (
                  <th key={index} className="border border-collapse border-gray-100 text-left bg-[#fffdec] p-1">
                    <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                      {column === "direction" ? "방향" : 
                       column === "동일매물" ? (
                         <div className="flex flex-col">
                           <span>동일</span>
                           <span>매물</span>
                         </div>
                       ) : column}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data[filteredColumnOrder[0]]?.map((_, rowIndex) => (
                <tr 
                  key={rowIndex} 
                  onClick={() => handleRowClick(data,rowIndex)}
                  className="cursor-pointer hover:bg-gray-200 relative group"
                >
                  {filteredColumnOrder.map((column, colIndex) => {
                    return <td key={colIndex} className='border border-collapse border-gray-100 text-left p-1'>{data[column][rowIndex]}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </>
  );
}

export default TableSalesComponent;