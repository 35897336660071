import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

const SubwayInfoTooltip = ({ subwayInfo, onClose, position }) => {
  // 지하철 정보 파싱
  const parseSubwayInfo = () => {
    if (!subwayInfo) return [];
    
    // 먼저 쉼표(,)로 분리
    const stationNames = subwayInfo.subwaystationname ? subwayInfo.subwaystationname.split(',') : [];
    const lineNames = subwayInfo.subwaylinename ? subwayInfo.subwaylinename.split(',') : [];
    const walkingTimes = subwayInfo.subwaywalkingtime ? subwayInfo.subwaywalkingtime.split(',') : [];
    const walkingDistances = subwayInfo.subwaywalkingdistance ? subwayInfo.subwaywalkingdistance.split(',') : [];
    const lineColors = subwayInfo.subwaylinecolor ? subwayInfo.subwaylinecolor.split(',') : [];
    
    // 결과 배열 초기화
    const result = [];
    
    // 역별 정보를 저장할 맵 생성
    const stationMap = new Map();
    
    // 각 역별로 정보 처리
    for (let i = 0; i < stationNames.length; i++) {
      const station = stationNames[i];
      const line = lineNames[i] || '';
      const time = walkingTimes[i] || '';
      const distance = walkingDistances[i] || '';
      const color = lineColors[i] || '#888888';
      
      // 파이프(|)로 분리된 값이 있는지 확인
      if (line.includes('|') || time.includes('|') || distance.includes('|') || color.includes('|')) {
        // 파이프로 분리된 값들
        const lineParts = line.split('|');
        const timeParts = time.split('|');
        const distanceParts = distance.split('|');
        const colorParts = color.split('|');
        
        // 가장 긴 배열의 길이 찾기
        const maxLength = Math.max(
          lineParts.length,
          timeParts.length,
          distanceParts.length,
          colorParts.length
        );
        
        // 각 파트별로 정보 추가
        for (let j = 0; j < maxLength; j++) {
          const currentLine = lineParts[Math.min(j, lineParts.length - 1)] || '';
          const currentTime = timeParts[Math.min(j, timeParts.length - 1)] || '';
          const currentDistance = distanceParts[Math.min(j, distanceParts.length - 1)] || '';
          const currentColor = colorParts[Math.min(j, colorParts.length - 1)] || '#888888';
          
          // 역 정보가 이미 맵에 있는지 확인
          if (stationMap.has(station)) {
            // 기존 역 정보 가져오기
            const stationInfo = stationMap.get(station);
            
            // 노선 정보 추가
            stationInfo.lines.push({
              name: currentLine,
              color: currentColor
            });
            
            // 도보 시간과 거리는 가장 짧은 값으로 업데이트
            if (parseInt(currentTime) < parseInt(stationInfo.walkingTime)) {
              stationInfo.walkingTime = currentTime;
            }
            if (parseInt(currentDistance) < parseInt(stationInfo.walkingDistance)) {
              stationInfo.walkingDistance = currentDistance;
            }
          } else {
            // 새로운 역 정보 맵에 추가
            stationMap.set(station, {
              station,
              lines: [{
                name: currentLine,
                color: currentColor
              }],
              walkingTime: currentTime,
              walkingDistance: currentDistance
            });
          }
        }
      } else {
        // 파이프로 분리되지 않은 일반 값
        // 역 정보가 이미 맵에 있는지 확인
        if (stationMap.has(station)) {
          // 기존 역 정보 가져오기
          const stationInfo = stationMap.get(station);
          
          // 노선 정보 추가
          stationInfo.lines.push({
            name: line,
            color: color
          });
          
          // 도보 시간과 거리는 가장 짧은 값으로 업데이트
          if (parseInt(time) < parseInt(stationInfo.walkingTime)) {
            stationInfo.walkingTime = time;
          }
          if (parseInt(distance) < parseInt(stationInfo.walkingDistance)) {
            stationInfo.walkingDistance = distance;
          }
        } else {
          // 새로운 역 정보 맵에 추가
          stationMap.set(station, {
            station,
            lines: [{
              name: line,
              color: color
            }],
            walkingTime: time,
            walkingDistance: distance
          });
        }
      }
    }
    
    // 맵의 값들을 결과 배열로 변환
    for (const stationInfo of stationMap.values()) {
      result.push(stationInfo);
    }
    
    return result;
  };
  
  const subwayData = parseSubwayInfo();
  
  // 중복 제거 (같은 역이 여러 번 나올 경우)
  const uniqueSubwayData = subwayData.reduce((acc, current) => {
    const x = acc.find(item => item.station === current.station);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  
  return (
    <div 
      className="absolute z-50 bg-white rounded-lg shadow-xl border border-gray-200 p-3 w-80"
      style={{
        top: position.y + 'px',
        left: position.x + 'px',
      }}
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-xs font-bold">지하철 정보</h3>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <MdOutlineCancel size={18} />
        </button>
      </div>
      
      {uniqueSubwayData.length > 0 ? (
        <table className="w-full text-xs">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="text-left py-1 px-2">역</th>
              <th className="text-left py-1 px-2">노선</th>
              <th className="text-left py-1 px-2">도보 시간(거리)</th>
            </tr>
          </thead>
          <tbody>
            {uniqueSubwayData.map((item, index) => (
              <tr key={index} className="border-b border-gray-100">
                <td className="py-1 px-2">{item.station}</td>
                <td className="py-1 px-2">
                  {item.lines.map((line, idx) => (
                    <span 
                      key={idx} 
                      className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium mr-1 mb-1"
                      style={{ 
                        backgroundColor: line.color,
                        color: '#ffffff', // 모든 노선 이름을 흰색으로 설정
                      }}
                    >
                      {line.name.trim()}
                    </span>
                  ))}
                </td>
                <td className="py-1 px-2">
                  {item.walkingTime}분 ({item.walkingDistance}m)
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-xs text-gray-500">지하철 정보가 없습니다.</p>
      )}
    </div>
  );
};

export default SubwayInfoTooltip; 