import React, { useState } from "react";
import PopularSection from "../components/PopularSection";
import PriceGapRank from "../components/PriceGapRank";
import TradingVolumeRank from "../components/TradingVolumeRank";

export default function Popular() {
    const [popularApt, setPopularApt] = useState([]);
    const [popularArea, setPopularArea] = useState([]);
    const [popularPriceGapRank, setPopularPriceGapRank] = useState([]);
    const [popularTradingVolumeRank, setPopularTradingVolumeRank] = useState([]);

    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("popular");


    const getPopularApt = () => {
        setLoading(true);
        // const apiUrl = `http://127.0.0.1:5001/popular/apt`;
        const apiUrl = `${process.env.REACT_APP_API_URL}/popular/apt`; // 실제 API 엔드포인트를 넣어주세요 

        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setPopularApt(data);
        })
        .catch((error) => {
            console.error('There was a problem with the fetchComplexes:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getPopularArea = () => {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/popular/area`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/popular/area`;
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setPopularArea(data);
        })
        .catch((error) => {
            console.error('There was a problem with the fetchComplexes:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getPopularPriceGapRank = (params) => {
        setLoading(true);
        // let apiUrl = `http://127.0.0.1:5001/popular/price_gap_rank`;
        let apiUrl = `${process.env.REACT_APP_API_URL}/popular/price_gap_rank`;

        if (params && params.toString()) {
            apiUrl += `?${params.toString()}`;
        }
        
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPopularPriceGapRank(data);
            })
            .catch((error) => {
                console.error('There was a problem with the fetchComplexes:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getPopularTradingVolumeRank = (params) => {
        setLoading(true);
        // let apiUrl = `http://127.0.0.1:5001/popular/trading_volume_rank`;
        let apiUrl = `${process.env.REACT_APP_API_URL}/popular/trading_volume_rank`;

        if (params && params.toString()) {
            apiUrl += `?${params.toString()}`;
        }
        
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPopularTradingVolumeRank(data);
            })
            .catch((error) => {
                console.error('There was a problem with the fetchComplexes:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="flex flex-col">
            <div className="flex justify-center bg-white rounded-full shadow-lg max-w-xl mx-auto my-4 w-full md:w-1/3 px-2">
                <div className="flex flex-wrap justify-around gap-2 w-full md:gap-2 p-2">
                    <button 
                        className={`px-2 py-1 text-sm rounded-full ${activeTab === "popular" 
                            ? "bg-green-600 text-white"
                            : "bg-gray-100 text-green-700 hover:text-white"}
                             hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50
                            `}
                        onClick={() => setActiveTab("popular")}
                    >
                        지금 인기
                    </button>
                    <button 
                        className={`px-2 py-1 text-sm rounded-full ${activeTab === "priceGap" 
                            ? "bg-green-600 text-white"
                            : "bg-gray-100 text-green-700 hover:text-white"}
                             hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50
                            `}
                        onClick={() => setActiveTab("priceGap")}
                    >
                        최고 상승 아파트
                    </button>
                    <button 
                        className={`px-2 py-1 text-sm rounded-full ${activeTab === "volume" 
                            ? "bg-green-600 text-white"
                            : "bg-gray-100 text-green-700 hover:text-white"}
                             hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50
                            `}
                        onClick={() => setActiveTab("volume")}
                    >
                        아파트 거래량
                    </button>
                </div>
            </div>
            <div className="flex justify-center">
                {activeTab === "popular" && (
                    <PopularSection 
                        popularApt={popularApt}
                        popularArea={popularArea}
                        getPopularApt={getPopularApt}
                        getPopularArea={getPopularArea}
                        loading={loading}
                    />
                )}
                {activeTab === "priceGap" && (
                    <PriceGapRank 
                        data={popularPriceGapRank}
                        getPopularPriceGapRank={getPopularPriceGapRank}
                        loading={loading}
                    />
                )}
                {activeTab === "volume" && (
                    <TradingVolumeRank 
                        data={popularTradingVolumeRank}
                        getPopularTradingVolumeRank={getPopularTradingVolumeRank}
                        loading={loading}
                    />
                )}
            </div>
        </div>
    );
}

