import React, { useState, useEffect, useRef } from 'react';
import cityCodesData from '../constant/city_codes.json';
import { MdOutlineCancel } from 'react-icons/md';
import RegionDropdownMetrix from './RegionDropdownMetrix';

const FilterComponent = ({ 
    data,
    onRegionSelect,
    onPriceFilterChange,
    onHouseholdFilterChange,
    onAreaFilterChange,
    onGapFilterChange,
    onMoveInDateFilterChange,  // 새로운 prop 추가
    onSubdistrictFilterChange,  // 새로운 prop 추가
    onSubwayFilterChange  // 새로운 prop 추가
}) => {
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [siDoData, setSiDoData] = useState(null);
    const [siGunGuData, setSiGunGuData] = useState(null);
    
    // 가격 필터 관련 상태
    const [showPriceTooltip, setShowPriceTooltip] = useState(false);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [isPriceFilterActive, setIsPriceFilterActive] = useState(false);
    
    // 총세대수 필터 관련 상태
    const [showHouseholdTooltip, setShowHouseholdTooltip] = useState(false);
    const [minHousehold, setMinHousehold] = useState('');
    const [maxHousehold, setMaxHousehold] = useState('');
    const [isHouseholdFilterActive, setIsHouseholdFilterActive] = useState(false);
    
    // 전용면적 필터 관련 상태 추가
    const [showAreaTooltip, setShowAreaTooltip] = useState(false);
    const [minArea, setMinArea] = useState('');
    const [maxArea, setMaxArea] = useState('');
    const [isAreaFilterActive, setIsAreaFilterActive] = useState(false);

    // 전매갭 필터 관련 상태 추가
    const [showGapTooltip, setShowGapTooltip] = useState(false);
    const [minGap, setMinGap] = useState('');
    const [maxGap, setMaxGap] = useState('');
    const [isGapFilterActive, setIsGapFilterActive] = useState(false);

    // 입주일 필터 관련 상태 수정 setShowMoveInDateTooltip
    const [showMoveInDateTooltip, setShowMoveInDateTooltip] = useState(false);
    const [minYears, setMinYears] = useState('');
    const [maxYears, setMaxYears] = useState('');
    const [isMoveInDateFilterActive, setIsMoveInDateFilterActive] = useState(false);

    // 동 필터 관련 상태 추가
    const [showSubdistrictTooltip, setShowSubdistrictTooltip] = useState(false);
    const [subdistrictOptions, setSubdistrictOptions] = useState([]);
    const [selectedSubdistricts, setSelectedSubdistricts] = useState([]);
    const [isSubdistrictFilterActive, setIsSubdistrictFilterActive] = useState(false);

    // 지하철 필터 관련 상태 추가
    const [showSubwayTooltip, setShowSubwayTooltip] = useState(false);
    const [isSubwayFilterActive, setIsSubwayFilterActive] = useState(false);
    const [hasSubway, setHasSubway] = useState(null);

    // 툴팁 참조 생성
    const priceTooltipRef = useRef(null);
    const priceButtonRef = useRef(null);
    const householdTooltipRef = useRef(null);
    const householdButtonRef = useRef(null);
    const areaTooltipRef = useRef(null);
    const areaButtonRef = useRef(null);
    const gapTooltipRef = useRef(null);
    const gapButtonRef = useRef(null);
    const moveInDateTooltipRef = useRef(null);
    const moveInDateButtonRef = useRef(null);
    const subdistrictTooltipRef = useRef(null);
    const subdistrictButtonRef = useRef(null);
    const subwayTooltipRef = useRef(null);
    const subwayButtonRef = useRef(null);

    // JSON 데이터를 사용하여 시도 데이터 설정
    useEffect(() => {
        const uniqueRegions = [
            ...[...new Map(cityCodesData.map(item => [item['지역명'], item['지역코드']])).values()]
                .map(code => ({ cortarName: cityCodesData.find(item => item['지역코드'] === code)['지역명'], cortarNo: code }))
        ];
        setSiDoData(uniqueRegions);
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            const cities = [
                ...cityCodesData
                    .filter(item => item['지역코드'] === selectedRegion)
                    .map(item => ({ cortarName: item['시군구명'], cortarNo: item['시군구코드'] }))
            ];
            setSiGunGuData([...new Map(cities.map(city => [city.cortarNo, city])).values()]);
        }
    }, [selectedRegion]);

    // 데이터에서 고유한 동(subdistrict_name) 목록 추출 - 수정
    useEffect(() => {
        if (data && Array.isArray(data) && data.length > 0) {
            const uniqueSubdistricts = [...new Set(data.map(item => item.subdistrict_name))]
                .filter(name => name) // null, undefined, empty string 제거
                .sort(); // 알파벳 순으로 정렬
            
            setSubdistrictOptions(uniqueSubdistricts);
            
            // 초기에는 모든 동을 선택 상태로 설정
            // 이미 선택된 동이 있고, 그 수가 이전 옵션 수와 같으면 새로운 옵션으로 업데이트
            if (selectedSubdistricts.length === 0 || 
                (selectedSubdistricts.length > 0 && selectedSubdistricts.length === subdistrictOptions.length)) {
                setSelectedSubdistricts(uniqueSubdistricts);
            }
        }
    }, [data, subdistrictOptions.length]);

    // 외부 클릭 시 툴팁 닫기
    useEffect(() => {
        function handleClickOutside(event) {
            if (priceTooltipRef.current && 
                !priceTooltipRef.current.contains(event.target) &&
                !priceButtonRef.current.contains(event.target)) {
                setShowPriceTooltip(false);
            }
            
            if (householdTooltipRef.current && 
                !householdTooltipRef.current.contains(event.target) &&
                !householdButtonRef.current.contains(event.target)) {
                setShowHouseholdTooltip(false);
            }
            
            if (areaTooltipRef.current && 
                !areaTooltipRef.current.contains(event.target) &&
                !areaButtonRef.current.contains(event.target)) {
                setShowAreaTooltip(false);
            }

            // 전매갭 필터 툴팁 처리 추가
            if (gapTooltipRef.current && 
                !gapTooltipRef.current.contains(event.target) &&
                !gapButtonRef.current.contains(event.target)) {
                setShowGapTooltip(false);
            }

            // 입주일 필터 툴팁 처리 추가
            if (moveInDateTooltipRef.current && 
                !moveInDateTooltipRef.current.contains(event.target) &&
                !moveInDateButtonRef.current.contains(event.target)) {
                setShowMoveInDateTooltip(false);
            }

            // 동 필터 툴팁 처리 추가
            if (subdistrictTooltipRef.current && 
                !subdistrictTooltipRef.current.contains(event.target) &&
                !subdistrictButtonRef.current.contains(event.target)) {
                setShowSubdistrictTooltip(false);
            }

            // 지하철 필터 툴팁 처리 추가
            if (subwayTooltipRef.current && 
                !subwayTooltipRef.current.contains(event.target) &&
                !subwayButtonRef.current.contains(event.target)) {
                setShowSubwayTooltip(false);
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // 가격 필터 버튼 상태 디버깅
    useEffect(() => {
        console.log("Button state:", {
            dataExists: !!data,
            dataLength: data ? data.length : 0,
        });
    }, [data]);

    const handleRegionChange = (e) => {
        const regionValue = e.target.value;
        setSelectedRegion(regionValue);
        setSelectedCity(""); // 시/군/구 초기화
        
        // 상위 컴포넌트로 선택된 지역 정보 전달
        const regionName = siDoData?.find(r => r.cortarNo === regionValue)?.cortarName || '';
        const regionData = { 
            regionCode: regionValue,
            cityCode: "",
            regionName: regionName,
            cityName: ""
        };
        
        onRegionSelect(regionData);
        
        // 지역이 변경되면 모든 필터 초기화
        resetAllFilters();
        
        // 자동 검색 플래그가 있고 지역이 선택된 경우 자동 검색 실행
        if (e.autoSearch && regionValue) {
            triggerSearch(regionData);
        }
    };
    
    const handleCityChange = (e) => {
        const cityValue = e.target.value;
        setSelectedCity(cityValue);
        
        // 상위 컴포넌트로 선택된 지역 정보 전달
        const regionName = siDoData?.find(r => r.cortarNo === selectedRegion)?.cortarName || '';
        const cityName = siGunGuData?.find(c => c.cortarNo === cityValue)?.cortarName || '';
        const regionData = {
            regionCode: selectedRegion,
            cityCode: cityValue,
            regionName,
            cityName
        };
        
        onRegionSelect(regionData);
        
        // 자동 검색 플래그가 있고 도시가 선택된 경우 자동 검색 실행
        if (e.autoSearch && cityValue) {
            triggerSearch(regionData);
        }
    };

    // 검색 트리거 함수
    const triggerSearch = (regionData) => {
        onRegionSelect({
            ...regionData,
            searchClicked: true
        });
    };

    // 가격 필터 적용 함수
    const applyPriceFilter = () => {
        // 입력값을 숫자로 변환 (콤마 제거)
        const minPriceValue = minPrice ? parseInt(minPrice.replace(/,/g, ''), 10) : null;
        const maxPriceValue = maxPrice ? parseInt(maxPrice.replace(/,/g, ''), 10) : null;
        
        setIsPriceFilterActive(minPriceValue !== null || maxPriceValue !== null);
        
        // 상위 컴포넌트로 가격 필터 정보 전달
        onPriceFilterChange({
            minPrice: minPriceValue,
            maxPrice: maxPriceValue
        });
        
        setShowPriceTooltip(false);
    };

    // 가격 필터 초기화 함수
    const resetPriceFilter = () => {
        setMinPrice('');
        setMaxPrice('');
        setIsPriceFilterActive(false);
        
        // 상위 컴포넌트로 필터 초기화 전달
        onPriceFilterChange({
            minPrice: null,
            maxPrice: null
        });
        setShowPriceTooltip(false);
    };
    
    // 총세대수 필터 적용 함수
    const applyHouseholdFilter = () => {
        // 입력값을 숫자로 변환
        const minHouseholdValue = minHousehold ? parseInt(minHousehold, 10) : null;
        const maxHouseholdValue = maxHousehold ? parseInt(maxHousehold, 10) : null;
        
        setIsHouseholdFilterActive(minHouseholdValue !== null || maxHouseholdValue !== null);
        
        // 상위 컴포넌트로 총세대수 필터 정보 전달
        onHouseholdFilterChange({
            minHousehold: minHouseholdValue,
            maxHousehold: maxHouseholdValue
        });
        
        setShowHouseholdTooltip(false);
    };

    // 총세대수 필터 초기화 함수
    const resetHouseholdFilter = () => {
        setMinHousehold('');
        setMaxHousehold('');
        setIsHouseholdFilterActive(false);
        
        // 상위 컴포넌트로 필터 초기화 전달
        onHouseholdFilterChange({
            minHousehold: null,
            maxHousehold: null
        });
        setShowHouseholdTooltip(false);
    };
    
    // 전용면적 필터 적용 함수
    const applyAreaFilter = () => {
        // 입력값을 숫자로 변환
        const minAreaValue = minArea ? parseFloat(minArea) : null;
        const maxAreaValue = maxArea ? parseFloat(maxArea) : null;
        
        setIsAreaFilterActive(minAreaValue !== null || maxAreaValue !== null);
        
        // 상위 컴포넌트로 전용면적 필터 정보 전달
        onAreaFilterChange({
            minArea: minAreaValue,
            maxArea: maxAreaValue
        });
        
        setShowAreaTooltip(false);
    };

    // 전용면적 필터 초기화 함수
    const resetAreaFilter = () => {
        setMinArea('');
        setMaxArea('');
        setIsAreaFilterActive(false);
        
        // 상위 컴포넌트로 필터 초기화 전달
        onAreaFilterChange({
            minArea: null,
            maxArea: null
        });
        setShowAreaTooltip(false);
    };

    // 전매갭 필터 입력 처리 함수 수정 (숫자, 소수점만 허용에서 천단위 콤마 추가)
    const handleGapInput = (e, setter) => {
        const value = e.target.value;
        // 숫자와 콤마만 허용
        if (/^[0-9,]*$/.test(value)) {
            // 콤마 제거 후 숫자만 추출
            const numericValue = value.replace(/,/g, '');
            // 콤마 추가하여 표시
            if (numericValue) {
                setter(numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
            } else {
                setter('');
            }
        }
    };

    // 전매갭 필터 적용 함수 수정
    const applyGapFilter = () => {
        // 입력값을 숫자로 변환 (콤마 제거)
        const minGapValue = minGap ? parseInt(minGap.replace(/,/g, ''), 10) : null;
        const maxGapValue = maxGap ? parseInt(maxGap.replace(/,/g, ''), 10) : null;
        
        console.log("전매갭 필터 적용:", { minGapValue, maxGapValue });
        
        setIsGapFilterActive(minGapValue !== null || maxGapValue !== null);
        
        // 상위 컴포넌트로 전매갭 필터 정보 전달
        onGapFilterChange({
            minGap: minGapValue,
            maxGap: maxGapValue
        });
        
        setShowGapTooltip(false);
    };
    
    // 전매갭 필터 초기화 함수
    const resetGapFilter = () => {
        setMinGap('');
        setMaxGap('');
        setIsGapFilterActive(false);
        
        onGapFilterChange({
        minGap: null,
        maxGap: null
        });
        
        setShowGapTooltip(false);
    };

    // 입주일 필터 적용 함수 수정
    const applyMoveInDateFilter = () => {
        // 입력값을 숫자로 변환
        const minYearsValue = minYears ? parseInt(minYears, 10) : null;
        const maxYearsValue = maxYears ? parseInt(maxYears, 10) : null;
        
        setIsMoveInDateFilterActive(minYearsValue !== null || maxYearsValue !== null);
        
        // 상위 컴포넌트로 입주일 필터 정보 전달
        onMoveInDateFilterChange({
            minYears: minYearsValue,
            maxYears: maxYearsValue
        });
        
        setShowMoveInDateTooltip(false);
    };
    
    // 입주일 필터 초기화 함수 수정
    const resetMoveInDateFilter = () => {
        setMinYears('');
        setMaxYears('');
        setIsMoveInDateFilterActive(false);
        
        onMoveInDateFilterChange({
            minYears: null,
            maxYears: null
        });
        
        setShowMoveInDateTooltip(false);
    };

    // 연수 입력 처리 함수 (숫자만 허용)
    const handleYearsInput = (e, setter) => {
        const value = e.target.value;
        // 숫자만 허용
        if (/^[0-9]*$/.test(value)) {
            setter(value);
        }
    };

    // 가격 입력 시 숫자와 콤마만 입력되도록 처리하는 함수
    const handlePriceInput = (e, setter) => {
        const value = e.target.value;
        // 숫자와 콤마만 허용
        if (/^[0-9,]*$/.test(value)) {
            // 콤마 제거 후 숫자만 추출
            const numericValue = value.replace(/,/g, '');
            // 콤마 추가하여 표시
            if (numericValue) {
                setter(numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
            } else {
                setter('');
            }
        }
    };
    
    // 세대수 입력 시 숫자만 입력되도록 처리하는 함수
    const handleHouseholdInput = (e, setter) => {
        const value = e.target.value;
        // 숫자만 허용
        if (/^[0-9]*$/.test(value)) {
            setter(value);
        }
    };
    
    // 전용면적 입력 시 숫자와 소수점만 입력되도록 처리하는 함수
    const handleAreaInput = (e, setter) => {
        const value = e.target.value;
        // 숫자와 소수점만 허용 (소수점은 하나만)
        if (/^[0-9]*\.?[0-9]*$/.test(value)) {
            setter(value);
        }
    };

    // 가격 필터 툴팁 토글
    const togglePriceTooltip = () => {
        // 지역이 선택되지 않았을 경우 경고 메시지 표시
        if (!selectedRegion) {
            alert("지역을 먼저 선택하세요");
            return;
        }
        
        setShowPriceTooltip(!showPriceTooltip);
        setShowHouseholdTooltip(false); // 다른 툴팁 닫기
        setShowAreaTooltip(false); // 다른 툴팁 닫기
        setShowGapTooltip(false); // 전매갭 툴팁 닫기
        setShowMoveInDateTooltip(false); // 입주일 툴팁 닫기
    };
    
    // 총세대수 필터 툴팁 토글
    const toggleHouseholdTooltip = () => {
        // 지역이 선택되지 않았을 경우 경고 메시지 표시
        if (!selectedRegion) {
            alert("지역을 먼저 선택하세요");
            return;
        }
        
        setShowHouseholdTooltip(!showHouseholdTooltip);
        setShowPriceTooltip(false); // 다른 툴팁 닫기
        setShowAreaTooltip(false); // 다른 툴팁 닫기
        setShowMoveInDateTooltip(false); // 입주일 툴팁 닫기
        setShowGapTooltip(false); // 전매갭 툴팁 닫기
    };
    
    // 전용면적 필터 툴팁 토글
    const toggleAreaTooltip = () => {
        // 지역이 선택되지 않았을 경우 경고 메시지 표시
        if (!selectedRegion) {
            alert("지역을 먼저 선택하세요");
            return;
        }
        
        setShowAreaTooltip(!showAreaTooltip);
        setShowPriceTooltip(false); // 다른 툴팁 닫기
        setShowHouseholdTooltip(false); // 다른 툴팁 닫기
        setShowMoveInDateTooltip(false); // 입주일 툴팁 닫기
        setShowGapTooltip(false); // 전매갭 툴팁 닫기
    };

    // 전매갭 필터 툴팁 토글 함수
    const toggleGapTooltip = () => {
        if (!selectedRegion) {
            alert("지역을 먼저 선택하세요");
            return;
        }
        
        setShowGapTooltip(!showGapTooltip);
        // 다른 툴팁들 닫기
        setShowPriceTooltip(false);
        setShowHouseholdTooltip(false);
        setShowAreaTooltip(false);
        setShowMoveInDateTooltip(false); // 입주일 툴팁 닫기
    };

    // 입주일 필터 툴팁 토글 함수
    const toggleMoveInDateTooltip = () => {
        if (!selectedRegion) {
            alert("지역을 먼저 선택하세요");
            return;
        }
        
        setShowMoveInDateTooltip(!showMoveInDateTooltip);
        // 다른 툴팁들 닫기
        setShowPriceTooltip(false);
        setShowHouseholdTooltip(false);
        setShowAreaTooltip(false);
        setShowGapTooltip(false);
    };

    // 동 필터 툴팁 토글 함수
    const toggleSubdistrictTooltip = () => {
        if (!selectedRegion) {
            alert("지역을 먼저 선택하세요");
            return;
        }
        
        setShowSubdistrictTooltip(!showSubdistrictTooltip);
        // 다른 툴팁들 닫기
        setShowPriceTooltip(false);
        setShowHouseholdTooltip(false);
        setShowAreaTooltip(false);
        setShowGapTooltip(false);
        setShowMoveInDateTooltip(false);
    };

    // 동 체크박스 변경 핸들러
    const handleSubdistrictChange = (subdistrict) => {
        let newSelectedSubdistricts;
        
        if (selectedSubdistricts.includes(subdistrict)) {
            // 이미 선택된 동이면 제거
            newSelectedSubdistricts = selectedSubdistricts.filter(item => item !== subdistrict);
        } else {
            // 선택되지 않은 동이면 추가
            newSelectedSubdistricts = [...selectedSubdistricts, subdistrict];
        }
        
        setSelectedSubdistricts(newSelectedSubdistricts);
    };

    // 모든 동 선택/해제 핸들러
    const handleSelectAllSubdistricts = (selectAll) => {
        if (selectAll) {
            setSelectedSubdistricts([...subdistrictOptions]);
        } else {
            setSelectedSubdistricts([]);
        }
    };

    // 동 필터 적용 함수 수정
    const applySubdistrictFilter = () => {
        // 선택된 동이 없는 경우 처리
        if (selectedSubdistricts.length === 0) {
            // 선택된 동이 없으면 필터 활성화 (아무것도 표시하지 않음)
            setIsSubdistrictFilterActive(true);
        } else {
            // 모든 동이 선택된 경우 필터를 비활성화
            const isAllSelected = selectedSubdistricts.length === subdistrictOptions.length;
            setIsSubdistrictFilterActive(!isAllSelected && selectedSubdistricts.length > 0);
        }
        
        // 상위 컴포넌트로 동 필터 정보 전달
        onSubdistrictFilterChange(selectedSubdistricts);
        
        setShowSubdistrictTooltip(false);
    };

    // 동 필터 초기화 함수 수정
    const resetSubdistrictFilter = () => {
        // 모든 동을 선택 상태로 설정 - 현재 옵션으로 업데이트
        const currentOptions = [...subdistrictOptions];
        setSelectedSubdistricts(currentOptions);
        setIsSubdistrictFilterActive(false);
        
        // 상위 컴포넌트로 필터 초기화 전달 - 빈 배열이 아닌 null을 전달하여 모든 동 표시
        onSubdistrictFilterChange(null);
        
        setShowSubdistrictTooltip(false);
    };

    // 지하철 필터 툴팁 토글 함수
    const toggleSubwayTooltip = () => {
        if (!selectedRegion) {
            alert("지역을 먼저 선택하세요");
            return;
        }
        
        setShowSubwayTooltip(!showSubwayTooltip);
        // 다른 툴팁들 닫기
        setShowPriceTooltip(false);
        setShowHouseholdTooltip(false);
        setShowAreaTooltip(false);
        setShowGapTooltip(false);
        setShowMoveInDateTooltip(false);
        setShowSubdistrictTooltip(false);
    };

    // 지하철 필터 적용 함수
    const applySubwayFilter = () => {
        setIsSubwayFilterActive(hasSubway !== null);
        
        // 상위 컴포넌트로 지하철 필터 정보 전달
        onSubwayFilterChange(hasSubway);
        
        setShowSubwayTooltip(false);
    };

    // 지하철 필터 초기화 함수
    const resetSubwayFilter = () => {
        setHasSubway(null);
        setIsSubwayFilterActive(false);
        
        // 상위 컴포넌트로 필터 초기화 전달
        onSubwayFilterChange(null);
        
        setShowSubwayTooltip(false);
    };

    // 필터 초기화 함수 수정
    const resetAllFilters = () => {
        // 모든 필터 상태 초기화
        setMinPrice('');
        setMaxPrice('');
        setIsPriceFilterActive(false);
        
        setMinHousehold('');
        setMaxHousehold('');
        setIsHouseholdFilterActive(false);
        
        setMinArea('');
        setMaxArea('');
        setIsAreaFilterActive(false);
        
        setMinGap('');
        setMaxGap('');
        setIsGapFilterActive(false);
        
        setMinYears('');
        setMaxYears('');
        setIsMoveInDateFilterActive(false);
        
        // 동 필터 초기화 - 현재 옵션으로 업데이트
        const currentOptions = [...subdistrictOptions];
        setSelectedSubdistricts(currentOptions);
        setIsSubdistrictFilterActive(false);
        
        // 상위 컴포넌트로 모든 필터 초기화 전달
        onPriceFilterChange({ minPrice: null, maxPrice: null });
        onHouseholdFilterChange({ minHousehold: null, maxHousehold: null });
        onAreaFilterChange({ minArea: null, maxArea: null });
        onGapFilterChange({ minGap: null, maxGap: null });
        onMoveInDateFilterChange({ minYears: null, maxYears: null });
        onSubdistrictFilterChange(null);
        
        // 지하철 필터 초기화
        setHasSubway(null);
        setIsSubwayFilterActive(false);
        
        // 모든 툴팁 닫기
        setShowPriceTooltip(false);
        setShowHouseholdTooltip(false);
        setShowAreaTooltip(false);
        setShowGapTooltip(false);
        setShowMoveInDateTooltip(false);
        setShowSubdistrictTooltip(false);
        setShowSubwayTooltip(false);
    };

    // 필터가 하나라도 적용되어 있는지 확인하는 변수
    const isAnyFilterActive = isPriceFilterActive || isHouseholdFilterActive || isAreaFilterActive || 
                             isGapFilterActive || isMoveInDateFilterActive || isSubdistrictFilterActive || 
                             isSubwayFilterActive;

    return (
        <div className="flex justify-center w-full">
            <section className="flex flex-col box-border m-1 p-1.5 w-full sm:w-[50vw]">
                {/* 필터 컨테이너 - 항상 표시되도록 수정 */}
                <div className="flex flex-col md:flex-row justify-around gap-2 p-2 mb-4 bg-white rounded-lg md:rounded-full shadow-lg">
                    <RegionDropdownMetrix 
                        selectedRegion={selectedRegion}
                        selectedCity={selectedCity}
                        onRegionChange={handleRegionChange}
                        onCityChange={handleCityChange}
                        siDoData={siDoData}
                        siGunGuData={siGunGuData}
                    />

                    {/* 필터 버튼들을 그리드로 배치 */}
                    <div className="grid grid-cols-2 md:flex md:flex-row gap-2 mt-2 md:mt-0">
                        {/* 입주일 필터 버튼 */}
                        <div className="relative">
                            <button 
                                ref={moveInDateButtonRef}
                                onClick={toggleMoveInDateTooltip}
                                className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                    isMoveInDateFilterActive 
                                    ? " bg-green-600 text-white font-medium" 
                                    : " text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                                } ${(!data || data.length === 0) ? " opacity-100" : ""}`}
                            >
                                {isMoveInDateFilterActive ? '입주일적용' : '입주일'}
                            </button>
                            
                            {/* 입주일 필터 툴팁 */}
                            {showMoveInDateTooltip && (
                                <div 
                                    ref={moveInDateTooltipRef}
                                    className="absolute md:right-5 md:right-1/2 md:transform md:translate-x-1/2 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl z-50 border border-gray-200"
                                >
                                    <div className="absolute -top-2 right-1/2 transform translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"></div>
                                
                                    <div className="flex items-center justify-between mb-2">
                                        <h3 className="text-sm font-bold">연식설정</h3>
                                        <button onClick={() => setShowMoveInDateTooltip(false)}>
                                            <MdOutlineCancel size={18} />
                                        </button>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                        <input
                                            type="text"
                                            value={minYears}
                                            onChange={(e) => handleYearsInput(e, setMinYears)}
                                            placeholder="최소 연수"
                                            className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                        />
                                        <span className="mx-1">~</span>
                                        <input
                                            type="text"
                                            value={maxYears}
                                            onChange={(e) => handleYearsInput(e, setMaxYears)}
                                            placeholder="최대 연수"
                                            className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                        />
                                    </div>
                                    <div className="text-xs text-gray-500 mb-2 text-right">
                                        (단위: 년)
                                    </div>
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={resetMoveInDateFilter}
                                            className="px-2 py-1 text-xs text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                        >
                                            초기화
                                        </button>
                                        <button
                                            onClick={applyMoveInDateFilter}
                                            className="px-2 py-1 text-xs text-white bg-green-600 rounded-lg hover:bg-green-700"
                                        >
                                            적용
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* 전용면적 필터 버튼 */}
                        <div className="relative">
                            <button 
                                ref={areaButtonRef}
                                onClick={toggleAreaTooltip}
                                className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                    isAreaFilterActive 
                                    ? "bg-green-600 text-white font-medium" 
                                    : "text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                                } ${(!data || data.length === 0) ? " opacity-100" : ""}`}
                            >
                                {isAreaFilterActive ? '면적적용' : '전용면적'}
                            </button>
                            
                            {/* 전용면적 필터 툴팁 */}
                            {showAreaTooltip && (
                            <div 
                                ref={areaTooltipRef}
                                className="absolute right-0 md:right-1/2 md:transform md:translate-x-1/2 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl z-50 border border-gray-200"
                        >
                            {/* Tooltip tail/arrow */}
                            <div className="absolute -top-2 right-1/2 transform translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"></div>
                            
                                <div className="flex items-center justify-between mb-2">
                                    <h3 className="text-sm font-bold">전용면적 설정</h3>
                                    <button onClick={() => setShowAreaTooltip(false)}>
                                        <MdOutlineCancel size={18} />
                                    </button>
                                </div>
                                <div className="flex items-center justify-between mb-3">
                                    <input
                                        type="text"
                                        value={minArea}
                                        onChange={(e) => handleAreaInput(e, setMinArea)}
                                        placeholder="최소 면적"
                                        className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                    />
                                    <span className="mx-1">~</span>
                                    <input
                                        type="text"
                                        value={maxArea}
                                        onChange={(e) => handleAreaInput(e, setMaxArea)}
                                        placeholder="최대 면적"
                                        className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                    />
                                </div>
                                <div className="text-xs text-gray-500 mb-2 text-right">
                                    (단위: m²)
                                </div>
                                <div className="flex justify-end space-x-2">
                                    <button
                                        onClick={resetAreaFilter}
                                        className="px-2 py-1 text-xs text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                    >
                                        초기화
                                    </button>
                                    <button
                                        onClick={applyAreaFilter}
                                        className="px-2 py-1 text-xs text-white bg-green-600 rounded-lg hover:bg-green-700"
                                    >
                                            적용
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* 총세대수 필터 버튼 */}
                    <div className="relative">
                        <button 
                            ref={householdButtonRef}
                            onClick={toggleHouseholdTooltip}
                            className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                isHouseholdFilterActive 
                                ? "bg-green-600 text-white font-medium" 
                                : "text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                            } ${(!data || data.length === 0) ? " opacity-100" : ""}`}
                        >
                            {isHouseholdFilterActive ? '세대수적용' : '세대수'}
                        </button>
                        
                        {/* 총세대수 필터 툴팁 */}
                        {showHouseholdTooltip && (
                            <div 
                                ref={householdTooltipRef}
                                className="absolute md:right-5 md:transform md:translate-x-1/2 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl z-50 border border-gray-200"
                            >
                                {/* Tooltip tail/arrow */}
                                <div className="absolute -top-2 right-1/2 transform translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"></div>
                                
                                <div className="flex items-center justify-between mb-2">
                                    <h3 className="text-sm font-bold">총세대수 설정</h3>
                                    <button onClick={() => setShowHouseholdTooltip(false)}>
                                        <MdOutlineCancel size={18} />
                                    </button>
                                </div>
                                <div className="flex items-center justify-between mb-3">
                                    <input
                                        type="text"
                                        value={minHousehold}
                                        onChange={(e) => handleHouseholdInput(e, setMinHousehold)}
                                        placeholder="최소 세대수"
                                        className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                    />
                                    <span className="mx-1">~</span>
                                    <input
                                        type="text"
                                        value={maxHousehold}
                                        onChange={(e) => handleHouseholdInput(e, setMaxHousehold)}
                                        placeholder="최대 세대수"
                                        className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                    />
                                </div>
                                <div className="text-xs text-gray-500 mb-2 text-right">
                                    (단위: 세대)
                                </div>
                                <div className="flex justify-end space-x-2">
                                    <button
                                        onClick={resetHouseholdFilter}
                                        className="px-2 py-1 text-xs text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                    >
                                        초기화
                                    </button>
                                    <button
                                        onClick={applyHouseholdFilter}
                                        className="px-2 py-1 text-xs text-white bg-green-600 rounded-lg hover:bg-green-700"
                                    >
                                        적용
                                    </button>
                                </div>
                                    </div>
                        )}
                                    </div>
                    
                        {/* 가격 필터 버튼 */}
                        <div className="relative">
                            <button 
                                ref={priceButtonRef}
                                onClick={togglePriceTooltip}
                                className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                    isPriceFilterActive 
                                    ? "bg-green-600 text-white font-medium" 
                                    : "text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                                } ${(!data || data.length === 0) ? " opacity-100" : ""}`}
                            >
                                {isPriceFilterActive ? '매매가적용' : '매매가'}
                            </button>
                            
                            {/* 가격 필터 툴팁 */}
                            {showPriceTooltip && (
                                <div 
                                    ref={priceTooltipRef}
                                    className="absolute right-0 md:right-1/2 md:transform md:translate-x-1/2 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl z-50 border border-gray-200"
                                >
                                    {/* Tooltip tail/arrow */}
                                    <div className="absolute -top-2 right-1/2 transform translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"></div>
                                    
                                    <div className="flex items-center justify-between mb-2">
                                        <h3 className="text-sm font-bold">매매가 설정</h3>
                                        <button onClick={() => setShowPriceTooltip(false)}>
                                            <MdOutlineCancel size={18} />
                                        </button>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                        <input
                                            type="text"
                                            value={minPrice}
                                            onChange={(e) => handlePriceInput(e, setMinPrice)}
                                            placeholder="최소 금액"
                                            className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                        />
                                        <span className="mx-1">~</span>
                                        <input
                                            type="text"
                                            value={maxPrice}
                                            onChange={(e) => handlePriceInput(e, setMaxPrice)}
                                            placeholder="최대 금액"
                                            className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                        />
                                        </div>
                                    <div className="text-xs text-gray-500 mb-2 text-right">
                                        (단위: 만원)
                                        </div>
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={resetPriceFilter}
                                            className="px-2 py-1 text-xs text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                        >
                                            초기화
                                        </button>
                                        <button
                                            onClick={applyPriceFilter}
                                            className="px-2 py-1 text-xs text-white bg-green-600 rounded-lg hover:bg-green-700"
                                        >
                                            적용
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        {/* 전매갭 필터 버튼 */}
                        <div className="relative">
                            <button 
                                ref={gapButtonRef}
                                onClick={toggleGapTooltip}
                                className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                    isGapFilterActive 
                                    ? "bg-green-600 text-white font-medium" 
                                    : "text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                                } ${(!data || data.length === 0) ? " opacity-100" : ""}`}
                            >
                                {isGapFilterActive ? '전매갭적용' : '전매갭'}
                            </button>
                            
                            {/* 전매갭 필터 툴팁 */}
                            {showGapTooltip && (
                                <div 
                                    ref={gapTooltipRef}
                                    className="absolute md:right-5 md:transform md:translate-x-1/2 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl z-50 border border-gray-200"
                                >
                                    <div className="absolute -top-2 right-1/2 transform translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"></div>
                                    
                                    <div className="flex items-center justify-between mb-2">
                                        <h3 className="text-sm font-bold">전매갭 설정</h3>
                                        <button onClick={() => setShowGapTooltip(false)}>
                                            <MdOutlineCancel size={18} />
                                        </button>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                        <input
                                            type="text"
                                            value={minGap}
                                            onChange={(e) => handleGapInput(e, setMinGap)}
                                            placeholder="최소 전매갭"
                                            className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                        />
                                        <span className="mx-1">~</span>
                                        <input
                                            type="text"
                                            value={maxGap}
                                            onChange={(e) => handleGapInput(e, setMaxGap)}
                                            placeholder="최대 전매갭"
                                            className="w-24 p-1 border border-gray-300 rounded-lg text-center text-sm"
                                        />
                                    </div>
                                    <div className="text-xs text-gray-500 mb-2 text-right">
                                        (단위: 만원)
                                    </div>
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={resetGapFilter}
                                            className="px-2 py-1 text-xs text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                        >
                                            초기화
                                        </button>
                                        <button
                                            onClick={applyGapFilter}
                                            className="px-2 py-1 text-xs text-white bg-green-600 rounded-lg hover:bg-green-700"
                                        >
                                            적용
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* 동 필터 버튼 */}
                        <div className="relative">
                            <button 
                                ref={subdistrictButtonRef}
                                onClick={toggleSubdistrictTooltip}
                                className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                    isSubdistrictFilterActive 
                                    ? "bg-green-600 text-white font-medium" 
                                    : "text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                                } ${(!data || data.length === 0) ? " opacity-100" : ""}`}
                            >
                                {isSubdistrictFilterActive ? '동필터적용' : '동필터'}
                            </button>
                            
                            {/* 동 필터 툴팁 */}
                            {showSubdistrictTooltip && (
                                <div 
                                    ref={subdistrictTooltipRef}
                                    className="absolute right-0 md:right-1/2 md:transform md:translate-x-1/2 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl z-50 border border-gray-200"
                                >
                                    <div className="absolute -top-2 right-1/2 transform translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"></div>
                                    
                                    <div className="flex items-center justify-between mb-2">
                                        <h3 className="text-sm font-bold">동 필터 설정</h3>
                                        <button onClick={() => setShowSubdistrictTooltip(false)}>
                                            <MdOutlineCancel size={18} />
                                        </button>
                                    </div>
                                    
                                    <div className="flex items-center justify-between mb-2 text-sm">
                                        <button 
                                            onClick={() => handleSelectAllSubdistricts(true)}
                                            className="px-2 py-1 text-xs bg-blue-50 hover:bg-blue-100 text-blue-700 rounded"
                                        >
                                            전체 선택
                                        </button>
                                        <button 
                                            onClick={() => handleSelectAllSubdistricts(false)}
                                            className="px-2 py-1 text-xs bg-gray-50 hover:bg-gray-100 text-gray-700 rounded"
                                        >
                                            전체 해제
                                        </button>
                                    </div>
                                    
                                    <div className="max-h-40 overflow-y-auto mb-3 border border-gray-100 rounded p-1">
                                        {subdistrictOptions.map((subdistrict, index) => (
                                            <div key={index} className="flex items-center mb-1">
                                                <input
                                                    type="checkbox"
                                                    id={`subdistrict-${index}`}
                                                    checked={selectedSubdistricts.includes(subdistrict)}
                                                    onChange={() => handleSubdistrictChange(subdistrict)}
                                                    className="mr-2"
                                                />
                                                <label htmlFor={`subdistrict-${index}`} className="text-sm">
                                                    {subdistrict}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={resetSubdistrictFilter}
                                            className="px-2 py-1 text-xs text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                        >
                                            초기화
                                        </button>
                                        <button
                                            onClick={applySubdistrictFilter}
                                            className="px-2 py-1 text-xs text-white bg-green-600 rounded-lg hover:bg-green-700"
                                        >
                                            적용
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* 지하철 필터 버튼 */}
                        <div className="relative">
                            <button 
                                ref={subwayButtonRef}
                                onClick={toggleSubwayTooltip}
                                className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                    isSubwayFilterActive 
                                    ? "bg-green-600 text-white font-medium" 
                                    : "text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                                } ${(!data || data.length === 0) ? " opacity-100" : ""}`}
                            >
                                {isSubwayFilterActive ? (hasSubway ? '지하철있음' : '지하철없음') : '지하철'}
                            </button>
                            
                            {/* 지하철 필터 툴팁 */}
                            {showSubwayTooltip && (
                                <div 
                                    ref={subwayTooltipRef}
                                    className="absolute md:right-5 md:transform md:translate-x-1/2 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl z-50 border border-gray-200"
                                >
                                    <div className="absolute -top-2 right-1/2 transform translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"></div>
                                    
                                    <div className="flex items-center justify-between mb-2">
                                        <h3 className="text-sm font-bold">지하철 필터 설정</h3>
                                        <button onClick={() => setShowSubwayTooltip(false)}>
                                            <MdOutlineCancel size={18} />
                                        </button>
                                    </div>
                                    
                                    <div className="flex flex-col space-y-2 mb-3">
                                        <div className="flex items-center">
                                            <input
                                                type="radio"
                                                id="subway-yes"
                                                name="subway-filter"
                                                checked={hasSubway === true}
                                                onChange={() => setHasSubway(true)}
                                                className="mr-2"
                                            />
                                            <label htmlFor="subway-yes" className="text-sm">지하철 있음</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="radio"
                                                id="subway-no"
                                                name="subway-filter"
                                                checked={hasSubway === false}
                                                onChange={() => setHasSubway(false)}
                                                className="mr-2"
                                            />
                                            <label htmlFor="subway-no" className="text-sm">지하철 없음</label>
                                        </div>
                                    </div>
                                    
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={resetSubwayFilter}
                                            className="px-2 py-1 text-xs text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                        >
                                            초기화
                                        </button>
                                        <button
                                            onClick={applySubwayFilter}
                                            className="px-2 py-1 text-xs text-white bg-green-600 rounded-lg hover:bg-green-700"
                                        >
                                            적용
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* 모든 필터 초기화 버튼 */}
                        <div className="col-span-2 md:col-span-1">
                            <button
                                onClick={resetAllFilters}
                                disabled={!isAnyFilterActive}
                                className={`px-2 py-1 text-xs md:text-sm rounded-full w-full ${
                                    isAnyFilterActive
                                        ? "bg-orange-500 text-white font-medium hover:bg-red-600"
                                        : "text-gray-500 bg-gray-50 border-gray-200 hover:bg-gray-200 hover:text-gray-600"
                                }`}
                            >
                                필터초기화
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FilterComponent; 