import React, { useState } from 'react';
import { MdLightbulbCircle } from "react-icons/md";

export default function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // 폼 데이터 가져오기
      const formData = {
        name: e.target.name.value,
        email: e.target.email.value,
        message: e.target.message.value
      };
      
      // 웹훅 URL이 없으면 하드코딩된 URL 사용
      const finalWebhookUrl = "https://hook.us1.make.com/td5sysrvqszy6ekgj85n18lvup4j1mi3";
      
      // 웹훅 호출
      const response = await fetch(finalWebhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message
        })
      });
      
      if (response.ok) {
        alert('메시지가 성공적으로 전송되었습니다.');
        // 폼 초기화
        e.target.reset();
      } else {
        const errorData = await response.text();
        throw new Error(errorData || '메시지 전송에 실패했습니다.');
      }
    } catch (error) {
      console.error('메시지 전송 중 오류 발생:', error);
      alert(`메시지 전송에 실패했습니다: ${error.message}`);
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <footer className='box-border m-2 p-4 md:p-10 flex flex-col md:flex-row justify-evenly items-center'>      
      <div className='w-full md:w-1/3 flex flex-col mb-4 md:mb-0 text-center md:text-left'>
        <h2 className='text-lg md:text-xl font-extrabold'>© 2025 Insight&Myung</h2>
        <p className='mt-2 md:mt-4 text-sm md:text-base'>📨: jinhom6225@insightmyung.com</p>
      </div>
      <div className='w-full md:w-1/3 flex justify-center md:justify-end mb-0'>
        <div className="relative group">
          <button 
            onClick={() => setIsModalOpen(true)}
            className="transition-all duration-300 hover:scale-110 hover:text-orange-300 cursor-pointer"
          >
            <MdLightbulbCircle size={36} className="md:w-12 md:h-12" />
          </button>
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
            문의사항
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-[#FFFEF6] p-4 rounded-lg w-full max-w-sm md:max-w-md">
            <h2 className="text-xl font-bold my-2">문의사항</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label className="block">이름</label>
                <input name="name" type="text" className="w-full border p-2 rounded bg-[#fffdee]" />
              </div>
              <div className="mb-2">
                <label className="block">이메일</label>
                <input name="email" type="email" className="w-full border p-2 rounded bg-[#fffdee]" />
              </div>
              <div className="mb-2">
                <label className="block">메시지</label>
                <textarea name="message" className="w-full border p-2 rounded h-24 bg-[#fffdee]" />
              </div>
              <div className="flex justify-end gap-2">
                <button 
                  type="button" 
                  onClick={() => setIsModalOpen(false)}
                  className="px-2 py-1 text-sm bg-gray-200 hover:bg-gray-300 text-gray-600 rounded"
                >
                  취소
                </button>
                <button 
                  type="submit"
                  className="px-2 py-1 text-sm bg-orange-300 hover:bg-orange-400 text-white rounded"
                >
                  전송
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </footer>
  );
}