import React, { useState, useEffect } from 'react';
import { MdInfoOutline, MdOutlineCancel } from 'react-icons/md';
import cityCodesData from '../constant/city_codes.json';
import { Link } from 'react-router-dom';
import RegionDropdown from './RegionDropdown';
import CustomSelect from './CustomSelect';
import { formatPrice } from '../util/helper';
import Spin from './Spin';

const PriceGapRank = ({ 
    data,
    loading,
    getPopularPriceGapRank
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [siDoData, setSiDoData] = useState(null);
    const [siGunGuData, setSiGunGuData] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState('최근 1개월');
    const [selectedType, setSelectedType] = useState('상승률');
    // 컴포넌트 마운트 시 초기 검색 실행
    useEffect(() => {
        // 이미 데이터가 있다면 초기 검색을 실행하지 않음
        if (data.length === 0) {
            const params = new URLSearchParams();
            params.append("rankingMonthType", "1");
            params.append("rankingSortType", "INCREASE_RATE");
            params.append("rankingTradeType", "A1");
            
            getPopularPriceGapRank(params);
        }
    }, []);  // data를 의존성 배열에 추가하지 않음

    // JSON 데이터를 사용하여 시도 데이터 설정
    useEffect(() => {
        const uniqueRegions = [
            { cortarName: "전국", cortarNo: "" },
            ...[...new Map(cityCodesData.map(item => [item['지역명'], item['지역코드']])).values()]
                .map(code => ({ cortarName: cityCodesData.find(item => item['지역코드'] === code)['지역명'], cortarNo: code }))
        ];
        setSiDoData(uniqueRegions);
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            const cities = [
                { cortarName: "전체", cortarNo: "" },
                ...cityCodesData
                    .filter(item => item['지역코드'] === selectedRegion)
                    .map(item => ({ cortarName: item['시군구명'], cortarNo: item['시군구코드'] }))
            ];
            setSiGunGuData([...new Map(cities.map(city => [city.cortarNo, city])).values()]);
        }
    }, [selectedRegion]);

    const handleRegionChange = (e) => {
        setSelectedRegion(e.target.value);
        setSelectedCity(""); // 시/군/구 초기화
    };
    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const calculateIncrease = (current, before) => {
        const difference = current - before;
        const percentage = ((difference / before) * 100).toFixed(1);
        return {
            difference: formatPrice(difference),
            percentage: percentage
        };
    };

    const periodOptions = [
        { value: "최근 1개월", label: "최근 1개월" },
        { value: "최근 3개월", label: "최근 3개월" },
        { value: "최근 6개월", label: "최근 6개월" },
        { value: "최근 1년", label: "최근 1년" }
    ];

    const typeOptions = [
        { value: "상승률", label: "상승률" },
        { value: "상승금액", label: "상승금액" }
    ];

    const convertPeriodToMonthType = (period) => {
        const periodMap = {
            "최근 1개월": "1",
            "최근 3개월": "3",
            "최근 6개월": "6",
            "최근 1년": "12"
        };
        return periodMap[period] || "1";
    };

    const convertTypeToSortType = (type) => {
        return type === "상승률" ? "INCREASE_RATE" : "INCREASE_AMOUNT";
    };

    const handleSearch = () => {
        const params = new URLSearchParams();
        
        if (selectedCity && selectedCity !== "") {
            params.append("legalDivisionNumber", selectedCity);
        } else if (selectedRegion && selectedRegion !== "") {
            params.append("legalDivisionNumber", selectedRegion);
        }

        params.append("rankingMonthType", convertPeriodToMonthType(selectedPeriod));
        params.append("rankingSortType", convertTypeToSortType(selectedType));
        params.append("rankingTradeType", "A1");

        getPopularPriceGapRank(params);
    };

    return (
        <div className="flex justify-center w-full">
        {loading ? (
            <div className="flex justify-center items-center p-8">
                <Spin size={32} /><span className="ml-3 text-lg sm:text-3xl text-gray-700">데이터를 불러오는 중입니다...</span>
            </div>
            ) :
            <section className="flex flex-col box-border m-1 p-1.5 w-full md:w-1/3">
                <div className="flex justify-center items-center mb-3">
                    <h1 className="text-lg font-bold">최고 상승 아파트</h1>
                    <div className="relative ml-2">
                        <MdInfoOutline
                            className="cursor-pointer text-gray-500 hover:text-gray-700"
                            onClick={() => setShowTooltip(!showTooltip)}
                        />
                        {showTooltip && (
                            <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 p-3 w-64 bg-white border border-gray-300 rounded-lg shadow-md z-10">
                                <div className="flex justify-between items-center mb-2">
                                    <span className="font-semibold">최고 상승·하락 아파트</span>
                                    <MdOutlineCancel
                                        className="w-5 h-5 cursor-pointer text-gray-500 hover:text-gray-700"
                                        onClick={() => setShowTooltip(false)}
                                    />
                                </div>
                                <p className="text-sm text-gray-700">
                                    직전 거래보다 실거래가가 가장 많이 상승 또는 하락한 아파트 순위를 제공합니다.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                
                <div className="flex flex-col sm:flex-row justify-between gap-2 p-2 mb-4 bg-white rounded-lg sm:rounded-full shadow-lg">
                    <div className="flex flex-wrap gap-2 justify-center sm:justify-start">
                        <RegionDropdown 
                            selectedRegion={selectedRegion}
                            selectedCity={selectedCity}
                            onRegionChange={handleRegionChange}
                            onCityChange={handleCityChange}
                            siDoData={siDoData}
                            siGunGuData={siGunGuData}
                        />

                        <CustomSelect
                            value={selectedPeriod}
                            onChange={(e) => setSelectedPeriod(e.target.value)}
                            options={periodOptions}
                        />

                        <CustomSelect
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}
                            options={typeOptions}
                        />
                    </div>

                    <button
                        onClick={handleSearch}
                        className="mt-2 sm:mt-0 px-2 py-1 bg-green-600 text-white rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 text-sm"
                    >
                        조회
                    </button>
                </div>

                <div className="">
                    {data.map((item, index) => {
                        const { difference, percentage } = calculateIncrease(item.currentPrice, item.beforePrice);
                        return (
                            <Link 
                                to={`/apartment/${item['complexNo']}`} 
                                key={`${item.rank}-${index}`} 
                                className="flex justify-between items-start p-2 border-b transition duration-300 ease-in-out hover:bg-orange-100 hover:scale-[1.01] hover:shadow-lg"
                            >
                                <div className="flex-1">
                                    <div className="flex items-center gap-2">
                                        <span className="font-bold text-sm sm:text-base">{item.rank}</span>
                                        <span className="font-bold text-sm sm:text-base">{item.complexName} {item.pyeongTypeName}㎡</span>
                                    </div>
                                    <div className="text-gray-500 text-xs sm:text-sm">
                                        {item.division} {item.sector}
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className="text-red-500 text-sm sm:text-base">
                                        +{difference} ({percentage}%)
                                    </div>
                                    <div className="text-gray-500 text-xs sm:text-sm">
                                        {formatPrice(item.beforePrice)} → {formatPrice(item.currentPrice)}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </section>
        }
    </div>
    );
};

export default PriceGapRank; 