// components/ResultOfApartmentContainer.jsx
import React, { useState, useMemo } from 'react';
import TableComponentAptMetrix from './TableComponentAptMetrix';
import FilterComponent from './FilterComponent';

export default function ResultOfAptMetrixContainer({ 
  isFetchingAptMetrixInfo, 
  aptMetrixInfoFetchError, 
  aptMetrixInfo, 
  columnOrderForAptMetrix,
  onSaleClick,
  onRegionSelect
}) {
  // 가격 필터 상태
  const [priceFilter, setPriceFilter] = useState({
    minPrice: null,
    maxPrice: null
  });
  
  // 총세대수 필터 상태
  const [householdFilter, setHouseholdFilter] = useState({
    minHousehold: null,
    maxHousehold: null
  });
  
  // 전용면적 필터 상태 추가
  const [areaFilter, setAreaFilter] = useState({
    minArea: null,
    maxArea: null
  });

  // 전매갭 필터 상태 추가
  const [gapFilter, setGapFilter] = useState({
    minGap: null,
    maxGap: null
  });

  // 입주일 필터 상태 수정
  const [moveInDateFilter, setMoveInDateFilter] = useState({
    minYears: null,
    maxYears: null
  });

  // 동 필터 상태 추가
  const [subdistrictFilter, setSubdistrictFilter] = useState([]);

  // 지하철 필터 상태 추가
  const [subwayFilter, setSubwayFilter] = useState(null);

  // 필터를 적용한 데이터
  const filteredData = useMemo(() => {
    if (!aptMetrixInfo) return null;
    
    // 필터가 설정되지 않은 경우 모든 데이터 반환
    if (
      priceFilter.minPrice === null && priceFilter.maxPrice === null && 
      householdFilter.minHousehold === null && householdFilter.maxHousehold === null &&
      areaFilter.minArea === null && areaFilter.maxArea === null &&
      gapFilter.minGap === null && gapFilter.maxGap === null &&
      moveInDateFilter.minYears === null && moveInDateFilter.maxYears === null &&
      subdistrictFilter === null && // null인 경우 모든 동 표시 (빈 배열이 아님)
      subwayFilter === null // 지하철 필터가 null이면 모든 데이터 표시
    ) {
      return aptMetrixInfo;
    }
    
    // 현재 날짜 계산
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    
    // 필터 적용
    return aptMetrixInfo.filter(item => {
      // 매매가 필터 적용
      if (priceFilter.minPrice !== null || priceFilter.maxPrice !== null) {
        // 매매가 데이터가 없는 경우 필터에서 제외
        if (item.sale_price === null) {
          return false;
        }
        
        // 문자열 형태의 가격을 숫자로 변환
        const salePrice = typeof item.sale_price === 'string' 
          ? parseInt(item.sale_price.replace(/,/g, ''), 10)
          : item.sale_price;
        
        // 숫자로 변환할 수 없는 경우도 필터에서 제외
        if (isNaN(salePrice)) {
          return false;
        }
        
        // 최소 가격 필터 적용
        if (priceFilter.minPrice !== null && salePrice < priceFilter.minPrice) {
          return false;
        }
        
        // 최대 가격 필터 적용
        if (priceFilter.maxPrice !== null && salePrice > priceFilter.maxPrice) {
          return false;
        }
      }
      
      // 총세대수 필터 적용
      if (householdFilter.minHousehold !== null || householdFilter.maxHousehold !== null) {
        // 총세대수 데이터가 없는 경우 필터에서 제외
        if (item.total_households === null || item.total_households === undefined) {
          return false;
        }
        
        // 문자열 형태의 세대수를 숫자로 변환
        const totalHouseholds = typeof item.total_households === 'string' 
          ? parseInt(item.total_households.replace(/,/g, ''), 10)
          : item.total_households;
        
        // 숫자로 변환할 수 없는 경우도 필터에서 제외
        if (isNaN(totalHouseholds)) {
          return false;
        }
        
        // 최소 세대수 필터 적용
        if (householdFilter.minHousehold !== null && totalHouseholds < householdFilter.minHousehold) {
          return false;
        }
        
        // 최대 세대수 필터 적용
        if (householdFilter.maxHousehold !== null && totalHouseholds > householdFilter.maxHousehold) {
          return false;
        }
      }
      
      // 전용면적 필터 적용
      if (areaFilter.minArea !== null || areaFilter.maxArea !== null) {
        // 전용면적 데이터가 없는 경우 필터에서 제외
        if (item.exclusive_area === null || item.exclusive_area === undefined) {
          return false;
        }
        
        // 문자열 형태의 전용면적을 숫자로 변환
        const exclusiveArea = typeof item.exclusive_area === 'string' 
          ? parseFloat(item.exclusive_area.replace(/,/g, ''))
          : item.exclusive_area;
        
        // 숫자로 변환할 수 없는 경우도 필터에서 제외
        if (isNaN(exclusiveArea)) {
          return false;
        }
        
        // 최소 전용면적 필터 적용
        if (areaFilter.minArea !== null && exclusiveArea < areaFilter.minArea) {
          return false;
        }
        
        // 최대 전용면적 필터 적용
        if (areaFilter.maxArea !== null && exclusiveArea > areaFilter.maxArea) {
          return false;
        }
      }

      // 전매갭 필터 적용
      if (gapFilter.minGap !== null || gapFilter.maxGap !== null) {
        console.log("전매갭 필터 적용 중:", { 
          itemGap: item.trading_gap, 
          minGap: gapFilter.minGap, 
          maxGap: gapFilter.maxGap 
        });
        
        // trading_gap 필드 사용
        const gapRaw = item.trading_gap;
        
        // trading_gap 데이터가 없으면 필터에서 제외
        if (gapRaw === null || gapRaw === undefined) {
          return false;
        }
        
        // 문자열일 경우 숫자로 변환 (콤마 제거 포함)
        const gapValue = typeof gapRaw === 'string'
          ? parseFloat(gapRaw.replace(/,/g, ''))
          : gapRaw;
        
        if (isNaN(gapValue)) {
          return false;
        }
        
        if (gapFilter.minGap !== null && gapValue < gapFilter.minGap) {
          return false;
        }
        
        if (gapFilter.maxGap !== null && gapValue > gapFilter.maxGap) {
          return false;
        }
      }

      // 입주일 필터 적용
      if (moveInDateFilter.minYears !== null || moveInDateFilter.maxYears !== null) {
        // 입주일 데이터가 없는 경우 필터에서 제외
        if (!item.move_in_date) {
          return false;
        }
        
        // 입주일에서 연도 추출 (YYYYDD 형식 가정)
        const moveInYear = parseInt(item.move_in_date.substring(0, 4), 10);
        
        // 유효한 연도가 아닌 경우 필터에서 제외
        if (isNaN(moveInYear)) {
          return false;
        }
        
        // 경과 연수 계산
        const yearsOld = currentYear - moveInYear;
        
        // 최소 연수 필터 적용
        if (moveInDateFilter.minYears !== null && yearsOld < moveInDateFilter.minYears) {
          return false;
        }
        
        // 최대 연수 필터 적용
        if (moveInDateFilter.maxYears !== null && yearsOld > moveInDateFilter.maxYears) {
          return false;
        }
      }
      
      // 동 필터 적용 수정
      if (subdistrictFilter && subdistrictFilter.length > 0) {
        // 동 데이터가 없는 경우 필터에서 제외
        if (!item.subdistrict_name) {
          return false;
        }
        
        // 선택된 동 목록에 포함되지 않으면 필터에서 제외
        if (!subdistrictFilter.includes(item.subdistrict_name)) {
          return false;
        }
      } else if (subdistrictFilter && subdistrictFilter.length === 0) {
        // 선택된 동이 없으면 모든 항목 제외 (아무것도 표시하지 않음)
        return false;
      }
      
      // 지하철 필터 적용
      if (subwayFilter !== null) {
        // 지하철 데이터가 있는지 확인 (subway_station 또는 subwayStationName 필드 확인)
        const hasSubway = (item.subway_station && item.subway_station !== null && item.subway_station !== "") || 
                          (item.subwaystationname && item.subwaystationname !== null && item.subwaystationname !== "");
        
        // 지하철 있음 필터 (true)인 경우, 지하철이 없으면 제외
        if (subwayFilter === true && !hasSubway) {
          return false;
        }
        
        // 지하철 없음 필터 (false)인 경우, 지하철이 있으면 제외
        if (subwayFilter === false && hasSubway) {
          return false;
        }
      }
      
      return true;
    });
  }, [aptMetrixInfo, priceFilter, householdFilter, areaFilter, gapFilter, moveInDateFilter, subdistrictFilter, subwayFilter]);

  // 가격 필터 변경 핸들러
  const handlePriceFilterChange = (newPriceFilter) => {
    setPriceFilter(newPriceFilter);
  };
  
  // 총세대수 필터 변경 핸들러
  const handleHouseholdFilterChange = (newHouseholdFilter) => {
    setHouseholdFilter(newHouseholdFilter);
  };
  
  // 전용면적 필터 변경 핸들러
  const handleAreaFilterChange = (newAreaFilter) => {
    setAreaFilter(newAreaFilter);
  };

  // 전매갭 필터 변경 핸들러
  const handleGapFilterChange = (newGapFilter) => {
    setGapFilter(newGapFilter);
  };

  // 입주일 필터 변경 핸들러
  const handleMoveInDateFilterChange = (newMoveInDateFilter) => {
    setMoveInDateFilter(newMoveInDateFilter);
  };

  // 동 필터 변경 핸들러
  const handleSubdistrictFilterChange = (selectedSubdistricts) => {
    setSubdistrictFilter(selectedSubdistricts);
  };

  // 지하철 필터 변경 핸들러 추가
  const handleSubwayFilterChange = (hasSubway) => {
    setSubwayFilter(hasSubway);
  };

  return (
    <>
      <FilterComponent 
        data={filteredData}
        onRegionSelect={onRegionSelect}
        onPriceFilterChange={handlePriceFilterChange}
        onHouseholdFilterChange={handleHouseholdFilterChange}
        onAreaFilterChange={handleAreaFilterChange}
        onGapFilterChange={handleGapFilterChange}
        onMoveInDateFilterChange={handleMoveInDateFilterChange}
        onSubdistrictFilterChange={handleSubdistrictFilterChange}
        onSubwayFilterChange={handleSubwayFilterChange}
      />
      
      {/* 필터 적용 알림 - 필터가 적용된 경우에만 표시 */}
      {(priceFilter.minPrice !== null || priceFilter.maxPrice !== null || 
        householdFilter.minHousehold !== null || householdFilter.maxHousehold !== null ||
        areaFilter.minArea !== null || areaFilter.maxArea !== null ||
        gapFilter.minGap !== null || gapFilter.maxGap !== null ||
        moveInDateFilter.minYears !== null || moveInDateFilter.maxYears !== null ||
        (subdistrictFilter && subdistrictFilter.length > 0 && 
         subdistrictFilter.length < (aptMetrixInfo ? [...new Set(aptMetrixInfo.map(item => item.subdistrict_name))].filter(Boolean).length : 0)) ||
        subwayFilter !== null) && (
        <div className="flex justify-center w-full">
          <div className="flex flex-col justify-between items-start my-2 p-2 bg-blue-50 border border-blue-200 rounded-md shadow-sm w-full md:w-1/2">
            {/* 모바일에서는 간략한 필터 정보만 표시 */}
            <div className="md:hidden w-full">
              <p className="text-sm text-blue-800 mb-1">
                <span className="font-medium">적용된 필터:</span> 
                {[
                  priceFilter.minPrice !== null || priceFilter.maxPrice !== null ? '매매가' : null,
                  householdFilter.minHousehold !== null || householdFilter.maxHousehold !== null ? '세대수' : null,
                  areaFilter.minArea !== null || areaFilter.maxArea !== null ? '전용면적' : null,
                  gapFilter.minGap !== null || gapFilter.maxGap !== null ? '전매갭' : null,
                  moveInDateFilter.minYears !== null || moveInDateFilter.maxYears !== null ? '입주연식' : null,
                  subdistrictFilter && subdistrictFilter.length > 0 && 
                  subdistrictFilter.length < (aptMetrixInfo ? [...new Set(aptMetrixInfo.map(item => item.subdistrict_name))].filter(Boolean).length : 0) ? '동필터' : null,
                  subwayFilter !== null ? '지하철' : null
                ].filter(Boolean).join(', ')}
              </p>
              
              {/* 필터링 결과 건수 */}
              {filteredData && (
                <p className="text-sm text-blue-800 mt-1">
                  <span className="px-2 py-0.5 bg-blue-100 rounded-full text-xs">
                    검색 결과: {filteredData.length}건
                    {aptMetrixInfo && ` / 전체 ${aptMetrixInfo.length}건`}
                  </span>
                </p>
              )}
            </div>
            
            {/* 데스크톱에서는 상세 필터 정보 표시 */}
            <div className="hidden md:block">
              {/* 매매가 필터 정보 */}
              {(priceFilter.minPrice !== null || priceFilter.maxPrice !== null) && (
                <p className="text-sm text-blue-800 mb-1">
                  <span className="font-medium">매매가 필터:</span> {priceFilter.minPrice ? priceFilter.minPrice.toLocaleString() : '0'}만원~ 
                  {priceFilter.maxPrice ? priceFilter.maxPrice.toLocaleString() : '무제한'}만원
                </p>
              )}
              
              {/* 총세대수 필터 정보 */}
              {(householdFilter.minHousehold !== null || householdFilter.maxHousehold !== null) && (
                <p className="text-sm text-blue-800 mb-1">
                  <span className="font-medium">총세대수 필터:</span> {householdFilter.minHousehold || '0'}세대~ 
                  {householdFilter.maxHousehold ? householdFilter.maxHousehold : '무제한'}세대
                </p>
              )}
              
              {/* 전용면적 필터 정보 */}
              {(areaFilter.minArea !== null || areaFilter.maxArea !== null) && (
                <p className="text-sm text-blue-800 mb-1">
                  <span className="font-medium">전용면적 필터:</span> {areaFilter.minArea || '0'}m²~ 
                  {areaFilter.maxArea ? areaFilter.maxArea : '무제한'}m²
                </p>
              )}

              {/* 전매갭 필터 정보 */}
              {(gapFilter.minGap !== null || gapFilter.maxGap !== null) && (
                <p className="text-sm text-blue-800 mb-1">
                  <span className="font-medium">전매갭 필터:</span> {gapFilter.minGap || '0'}만원~ 
                  {gapFilter.maxGap ? gapFilter.maxGap : '무제한'}만원
                </p>
              )}

              {/* 입주일 필터 정보 */}
              {(moveInDateFilter.minYears !== null || moveInDateFilter.maxYears !== null) && (
                <p className="text-sm text-blue-800 mb-1">
                  <span className="font-medium">입주연식 필터:</span> {moveInDateFilter.minYears || '0'}~ 
                  {moveInDateFilter.maxYears ? moveInDateFilter.maxYears : '무제한'}년
                </p>
              )}
              
              {/* 동 필터 정보 - 모든 동이 선택된 경우 표시하지 않음 */}
              {subdistrictFilter && subdistrictFilter.length > 0 && 
               subdistrictFilter.length < (aptMetrixInfo ? [...new Set(aptMetrixInfo.map(item => item.subdistrict_name))].filter(Boolean).length : 0) && (
                <p className="text-sm text-blue-800 mb-1">
                  <span className="font-medium">동 필터:</span> {subdistrictFilter.join(', ')}
                </p>
              )}

              {/* 지하철 필터 정보 */}
              {subwayFilter !== null && (
                <p className="text-sm text-blue-800 mb-1">
                  <span className="font-medium">지하철 필터:</span> {subwayFilter ? '있음' : '없음'}
                </p>
              )}

              {/* 필터링 결과 건수 */}
              {filteredData && (
                <p className="text-sm text-blue-800 mt-1">
                  <span className="px-2 py-0.5 bg-blue-100 rounded-full text-xs">
                    검색 결과: {filteredData.length}건
                    {aptMetrixInfo && ` / 전체 ${aptMetrixInfo.length}건`}
                  </span>
                </p>
              )}
            </div>
              
          </div>
        </div>
      )}

      <TableComponentAptMetrix 
        columnOrder={columnOrderForAptMetrix} 
        data={filteredData} 
        onSaleClick={onSaleClick}
        isLoading={isFetchingAptMetrixInfo}
        error={aptMetrixInfoFetchError}
      />
    </>
  );
}